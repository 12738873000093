import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { login, reset } from '../../features/auth/authSlice';
import Spinner from '../../components/Spinner';
import {
    TextField,
    Button,
    Typography,
    Grid,
    Link,
    Checkbox,
    Box,
    Paper,
    Container,
    FormControlLabel,
    InputAdornment,
    IconButton
} from "@mui/material";
import backgroundimage from "../../images/arvam-login-2-base.png";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { myConfig } from '../../settings';
import { apiService3 } from '../../features/apiService';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function ResetPasword() {
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmpassword: '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isValid2, setIsValid2] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setIsValid(validatePassword(newPassword))
    };

    const handleChange2 = (event) => {
        const newPassword = event.target.value;
        setPassword2(newPassword);
        setIsValid2(validatePassword(newPassword))
    };

    const validatePassword = (password) => {
        const isValidPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/.test(password);
        return isValidPassword;
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };


    const { id } = useParams();
    const onSubmit = (e) => {
        e.preventDefault()
        console.log("Reset Password: id ->", id);
        if (password !== password2) {
            toast.error("Password Mismatch");
            return;
        } else {
            setIsLoading(true)
            console.log("Reset Password: id ->", id);
            console.log("Reset Password: password ->", password);
            apiService3('POST', myConfig.resetPasswordEP, { email: id, password: password })
                .then((response) => {
                    setIsLoading(false)
                    console.log("Reset Password: response ->", response);
                    if (response.data.message === 'Password has been reset') {
                        toast.success("Password Reset Successfully");
                    //    navigate('/login')
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch((error) => {
                    setIsLoading(false)
                    console.log("Reset Password: error ->", error);
                    toast.error(error);
                })
        }
    }

    if (isLoading) {
        return <Spinner />
    }


    return (

        <Container component="main" maxWidth="lg">

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        width: '400px',
                        padding: '30px',
                        borderRadius: '10px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                        textAlign: 'center',
                    }}
                    component={Paper}
                >
                    <Typography component="h1" variant="h3">
                        Reset Password
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={onSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            fullWidth
                            onChange={handleChange}
                            placeholder="Enter your New password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            edge="end" >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {
                            !isValid && <Typography variant="caption" color={"error"}>
                                Password must contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character
                            </Typography>
                        }
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmpassword"
                            label="Enter Confirm Password"
                            type={showPassword2 ? 'text' : 'password'}
                            id="confirmpassword"
                            autoComplete="current-password"
                            onChange={handleChange2}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibility2}
                                            edge="end" >
                                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {
                            !isValid && <Typography variant="caption" color={"error"} sx={{textAlign:'left'}}>
                                Password must contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character
                            </Typography>
                        }
                        <Button type="submit" variant="contained" color="primary"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Reset Password
                        </Button>

                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default ResetPasword;
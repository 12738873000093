import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { DialogActions, Grid, Typography, useTheme } from "@mui/material";
import { Box, Button, Dialog } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useSelector } from 'react-redux';
import { assetModel } from "../../schema/schema.js";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { Image } from "@mui/icons-material";

// Globals
const EXCLUDE_ATTR = ["_id", "params", "__v", "inspectionList"];

const AssetView = (props) => {
  const location = useLocation();
  const [open, setOpen] = useState(props.open.state);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useSelector((state) => state.auth);
  const [asset, setAsset] = React.useState([]);
  const [formValues, setFormValues] = React.useState(assetModel);

  let headeList = [
    { id: "datecreated", label: "Date Created" },
    { id: "createdOn", label: "Created On" },
    { id: "createdBy", label: "Created By" },
    { id: "isInspection", label: "Inspection" },
  ]

  function getInitialValues() {
    let fieldvalues = [];
    let id = 0;
    for (const [key, value] of Object.entries(props.open.asset)) {
      if (value != null && value != '') {
        if (!EXCLUDE_ATTR.includes(key) && key != 'specifications' && key != 'lastinspection' && key != 'geo' && key != 'subRows' && key != 'statetimestamps' && key != 'sensordata' && key != 'facility' && key != 'assetfamilyid') {
          if (key.includes("date") || key.includes("time") || key.includes("from") || key.includes("to") || key.includes("timestamp") || key.includes("createdOn")) {
            let title = assetModel.find((item) => item.field == key);
            if (!title) {
              title = headeList.find((item) => item.id == key);
            }
            fieldvalues.push({ "id": id, "Asset Attribute": title?.label || key, "Value": timeZoneConverter(value) });
          } else if (value == true || value == false) {
            let title = assetModel.find((item) => item.field == key);
            if (!title) {
              title = headeList.find((item) => item.id == key);
            }
            fieldvalues.push({ "id": id, "Asset Attribute": title?.label || key, "Value": value == true ? 'Yes' : 'No' });
          } else {
            let title = assetModel.find((item) => item.field == key);
            if (!title) {
              title = headeList.find((item) => item.id == key);
            }
            fieldvalues.push({ "id": id, "Asset Attribute": title?.label || key, "Value": value });
          }
          ++id;
        }
      }
    }
    setAsset(fieldvalues);
  }

  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    props.open.setfn();
  }

  // To open this dialog box
  if (props.open.state & !open) {
    setOpen(true);
    getInitialValues();
  }

  //

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg">
      <Box m="5px" p="10px">
        <Header
          title="ASSET DETAILS"
          subtitle={"Name: " + props.open.asset.name + " ID: " + props.open.asset.assetid} />
        <Box
          m="40px 0 0 0"
          height="70vh"
          p="10px">
          <Grid container spacing={4}>
            {
              asset.map((item, index) => {
                return (
                  <Grid item xs={3} key={index}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {item['Asset Attribute']}
                    </Typography>
                    {item['Asset Attribute'] != 'fileurl' ? <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                      {item['Value'] != null ? item['Value'] : "N/A"}
                    </Typography> : <img src={item['Value']} alt="Asset" width={50} />}
                  </Grid>

                );
              })
            }
          </Grid>
          <DialogActions>
            <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
}
export default AssetView;
import React, { useState, useEffect } from "react";
import { Checkbox, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { apiService2 } from "../../features/apiService";

import { toast } from "react-toastify";
// API End Point

let fakecount = 0;
const ViewAccessControl = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('');
    const [accesslist, setAccessList] = useState([]);
    const [accesscontrollist, setAccessControlList] = useState([]);
    const [accesscontrol, setAccessControl] = useState('');
    const [isChange, setChange] = useState(false);


    if (props.open.state & !open) {
        setOpen(true);
        let accList = [];
        const resource = props.open.resource;
        console.log(resource);
        setAccessControl(resource.accesscategory);
        if (resource.accesslist && resource.accesslist.length > 0) {
            resource.accesslist.forEach(element => {
                accList.push({ id: element.id, name: element.name, isCreate: element.isCreate, isView: element.isView, isEdit: element.isEdit, isDeleted: element.isDeleted, isAll: element.isAll });
            });
        }
        setAccessList(accList)
    }

    useEffect(() => {
        apiService2('GET', myConfig.getAccessCategory)
            .then(response => {
                setAccessControlList(response.data)
                if (response.data.length == 0) {
                    // toast.error("Access Control list Empty, Please add access Control first");
                }
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    let modAccessList = [];
    for (const item of accesscontrollist) {
        modAccessList.push(item.name);
    }


    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }

    function handleChange(event, filed, i) {
        let accesslistTemp = accesslist;
        if (filed == 'isAll' || (filed == 'isDeleted' && event.target.checked)) {
            accesslistTemp[i]['isCreate'] = event.target.checked;
            accesslistTemp[i]['isView'] = event.target.checked;
            accesslistTemp[i]['isEdit'] = event.target.checked;
            accesslistTemp[i]['isDeleted'] = event.target.checked;
            accesslistTemp[i]['isAll'] = event.target.checked;
        } else {
            accesslistTemp[i][filed] = event.target.checked;
            if (accesslistTemp[i]['isCreate'] && accesslistTemp[i]['isView'] && accesslistTemp[i]['isEdit'] && accesslistTemp[i]['isDeleted']) {
                accesslistTemp[i]['isAll'] = true;
            } else {
                accesslistTemp[i]['isAll'] = false;
            }
        }
        setAccessList([...accesslistTemp]);
    }


    function handleSave() {
        const resource = props.open.resource;
        let details = { _id: resource._id, accesslist: accesslist, accesscategory: accesscontrol }
        apiService2('POST', myConfig.updateResourceAccess, details)
            .then(resp => {
                if (resp.data.message) {
                    setErrorMessage(resp.data.message);
                    setSubmitionError(true);
                } else {
                    setSubmitionCompleted(true);
                }
            })
            .catch(error => {
                setSubmitionError(true);
                setSubmitionCompleted(true);
            });

    }

    function handleView() {
        setChange(!isChange);
    }

    function handleUpdate() {
        var access = accesscontrollist.find(x => x.name == accesscontrol);
        var accList = [];
        setChange(!isChange);
        access.accesslist.forEach(element => {
            accList.push({ name: element.name, id: element.id, isCreate: element.isCreate, isView: element.isView, isEdit: element.isEdit, isDeleted: element.isDeleted, isAll: element.isAll });
        });
        setAccessList(accList)
    }



    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">View Access Control</DialogTitle>
                        <DialogContent>
                            {!isChange ? <DialogContentText variant="h6">
                                Access Category:   {accesscontrol}
                            </DialogContentText>
                                : <DialogContentText variant="h6">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="outlined-basic"
                                                label={'Access Control'}
                                                name={'accesscontrol'}
                                                value={accesscontrol}
                                                onChange={(event) => setAccessControl(event.target.value)}
                                                margin="normal"
                                                type="text"
                                                select
                                                defaultValue={''}
                                                sx={{ display: 'flex', width: "100%" }}>
                                                {modAccessList.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </DialogContentText>}
                            <DialogActions>
                                {!isChange ? <Button type="button" variant="contained" color="secondary" onClick={handleView}>
                                    Change Access
                                </Button> :
                                    <>
                                        <Button type="button" variant="contained" color="secondary" onClick={handleView}>Cancel </Button>
                                        <Button type="button" variant="contained" color="secondary" onClick={handleUpdate}> Update</Button>
                                    </>}
                            </DialogActions>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ textAlign: "left" }}>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Create</TableCell>
                                            <TableCell>Read</TableCell>
                                            <TableCell>Update</TableCell>
                                            <TableCell>Delete</TableCell>
                                            <TableCell>All</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {accesslist.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell> <Checkbox
                                                    labelStyle={{ color: 'white' }}
                                                    iconStyle={{ fill: 'white' }}
                                                    color="secondary"
                                                    checked={row.isCreate}
                                                    onChange={(event) => handleChange(event, 'isCreate', index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></TableCell>
                                                <TableCell><Checkbox
                                                    labelStyle={{ color: 'white' }}
                                                    iconStyle={{ fill: 'white' }}
                                                    color="secondary"
                                                    checked={row.isView}
                                                    onChange={(event) => handleChange(event, 'isView', index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></TableCell>
                                                <TableCell> <Checkbox
                                                    labelStyle={{ color: 'white' }}
                                                    iconStyle={{ fill: 'white' }}
                                                    color="secondary"
                                                    checked={row.isEdit}
                                                    onChange={(event) => handleChange(event, 'isEdit', index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></TableCell>
                                                <TableCell> <Checkbox
                                                    labelStyle={{ color: 'white' }}
                                                    iconStyle={{ fill: 'white' }}
                                                    color="secondary"
                                                    checked={row.isDeleted}
                                                    onChange={(event) => handleChange(event, 'isDeleted', index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        labelStyle={{ color: 'white' }}
                                                        iconStyle={{ fill: 'white' }}
                                                        color="secondary"
                                                        checked={row.isAll}
                                                        onChange={(event) => handleChange(event, 'isAll', index)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="button" variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </DialogActions>

                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
                        <DialogContent>
                            <DialogContentText variant="h6">Thanks! Access Control Modified</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Access Control Modified Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default ViewAccessControl;
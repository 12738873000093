import { Box, Typography, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, IconButton, Tooltip } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { assetfamilyModel } from "../../schema/schema";
import AddAssetFamily from "./addassetfamily";
import EditAssetFamily from "./editassetfamily";
import ViewAssetFamily from "./viewassetfamily";
import { getAccess } from "../../features/helpers/authhelper";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";
import AddAspect from "./aspect.jsx";

const AssetFamily = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAddAspect, setOpenAddAspect] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [assetfamilyList, setassetfamilyList] = useState([]);
    const [assetfamily, setassetfamily] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [isDeleted, setIsDeleted] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("");
    const access = getAccess(user, 'setup');


    for (const item of assetfamilyModel) {
        if (item.hasOwnProperty("isView")) {
            if (!item.isView) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        apiService2('GET', myConfig.getAssetfamily)
            .then(response => {
                var assetfamily = response.data;
                assetfamily?.forEach(res => {
                    res.warrantyexpiry = timeZoneConverter(res.warrantyexpiry);
                    res.cdate = timeZoneConverter(res.createdOn)
                })
                setassetfamilyList(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of assetfamilyList) {
                if (temp._id == wrnumber) {
                    setassetfamily(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {

            setassetfamily({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
        setOpenEdit(false)
        setOpenView(false)
        setOpenDelete(false)
        setOpenAddAspect(false)
        setRefresh(!refresh);
        setIsDeleted(false);
        setassetfamily({});
    }

    function handleClickOpen() {
        setOpen(true);
        setRefresh(false);
    }

    function handleDelete() {
        apiService2('POST', myConfig.deleteAssetfamily, { "_id": assetfamily._id })
            .then((result) => {
                if(result?.data?.message == 'Asset Family is already used in Asset!') {
                    toast.error(result?.data?.message);
                } else {
                    toast.success("Asset Family Deleted Successfully");
                }
                handleClose();
            })
            .catch(() => {
                handleClose();
            });
    }

    function CustomToolbar() {
        return (
            <Box className="mb-1">
                {
                    access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Add
                    </Button>
                }
            </Box>
        );
    }
    return (
        <Box m="20px 20px 20px 20px">
            <Box height="70vh">
                {CustomToolbar()}
                {tableView(
                    false, columns, assetfamilyList, setRowSelection,
                    rowSelection, '_id',
                    true,
                    ({ row }) => [
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            {
                                access.isEdit && <Tooltip title="Edit">
                                    <IconButton
                                        color="secondary"
                                        onClick={() => {
                                            setassetfamily({ ...row.original })
                                            setOpenEdit(true);
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                access.isDeleted && <Tooltip title="Delete">
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            setassetfamily({ ...row.original })
                                            setOpenDelete(true);
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="View">
                                <IconButton
                                    onClick={() => {
                                        setassetfamily({ ...row.original })
                                        setOpenView(true);
                                    }}>
                                    <PreviewIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Add Aspect">
                                <IconButton
                                    onClick={() => {
                                        setassetfamily({ ...row.original })
                                        setOpenAddAspect(true);
                                    }}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ])
                }
                <AddAssetFamily open={{ state: open, setfn: handleClose }} />
                <EditAssetFamily open={{ state: openEdit, setfn: handleClose, assetfamily: assetfamily }} />
                <ViewAssetFamily open={{ state: openView, setfn: handleClose, assetfamily: assetfamily }} />
                <AddAspect open={{ state: openAddAspect, setfn: handleClose, assetfamily: assetfamily }} />
                <Dialog
                    open={openDelete}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Delete Asset Family</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to delete?</DialogContentText>
                            <DialogActions >
                                <Button type="button" variant="contained" color="success" onClick={handleDelete}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Box>
    );
};

export default AssetFamily;

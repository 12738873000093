import React from 'react';
import { Box } from "@mui/material";
import {
  Button,
  withStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Grid
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useSelector } from 'react-redux';
import { useState, useEffect } from "react";
import { myConfig } from "../../settings.js";
//import  SelectField from "./Forms/SelectField.jsx";
import { apiService } from '../../features/apiService.js';
import { workRequestModel } from "../../schema/schema.js";
import { toast } from 'react-toastify';
import { formCreator } from "../../features/helpers/formhelper";

let fakecount = 0

function AddWR(props) {
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [mainLibList, setMainLibList] = useState([]);
  const initValues = getInitialValues();
  const [initialValues, setInitialValues] = useState(initValues);
  const formFields = workRequestModel;
  
  const endPoint = myConfig.addWorkRequestEP; // API End Point


  function getInitialValues() {
    let ivalues = {};
    for (const item of workRequestModel) {
      ivalues[item.field] = "";
    }
    ++fakecount;
    return ivalues;
  }

  function updateAssetList(resp) {
    var modAssetList = [];
    for (var asset of resp) {
      if (asset.state === "Operational") modAssetList.push(asset);
    }
    setAssetList(modAssetList);
  }

  useEffect(() => {
    apiService(myConfig.getAssetsEP)
      .then(response => {
        updateAssetList(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  useEffect(() => {
    apiService(myConfig.getMainLibEP)
      .then(response => {
        setMainLibList(response.data);
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);
  const [deptList, setDeptList] = useState([]);

  useEffect(() => {
    apiService(myConfig.getDeptEP)
      .then(response => {
        setDeptList(response.data)
        if (response.data.length == 0) {
          console.log("Dept List->", response.data.length);
          toast.error("Department list Empty, Please add department first");
        }
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  let modDeptList = [];
  for (const deptItem of deptList) {
    if (deptItem.name != user.dept) modDeptList.push(deptItem.name)
  }

  let modAssetList = [];
  assetList.forEach((item) => {
    if (item.state == "Operational") modAssetList.push(item.name + "-" + item.assetid);
  })
  let modMainLibList = [];
  mainLibList.forEach((item) => {
    modMainLibList.push(item.name + "-" + item.code);
  })
  const valueDB = { "asset": modAssetList, "Maintenance Library": modMainLibList, "dept": modDeptList };
  const mtList = [
    { name: "Preventive", _id: "1" },
    { name: "Failure", _id: "2" }
  ];

  // Handle States for the dialog box
  function handleClose() {
    // console.log("handleClose: Setting open to false");
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }

  function handleClickOpen() {
    // console.log(["Comp: open->", true])
    setSubmitionCompleted(false);
    setOpen(true);
  }

  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
  }

  function handleSelect() {

  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Create Work Request</DialogTitle>
            <DialogContent>
              <Formik
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  var modValues = JSON.parse(JSON.stringify(values));
                  if (user.dept === modValues.assigneddept) {
                    toast.error("Workrequest cannot be assigned to your dept")
                    return;
                  }
                  setSubmitting(true);
                  console.log("submit->", modValues,user);
                  modValues['tenantid'] = user.tenantid;
                  const asset = modValues["assetid"];
                  modValues["assetname"] = asset.split("-")[0];
                  modValues["assetid"] = asset.split("-")[1];
                  delete modValues["asset"];
                  apiService(endPoint, modValues)
                    .then(resp => {
                      setSubmitionCompleted(true);
                    })
                    .catch(error => {
                      console.log(["Add WR - Errored->", error]);
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    });
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
  
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue)}
                      </Box>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: setInitialValues(initValues) })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting} onClick={handleSubmit}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Work Request added</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Add Work Request Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Try Again Later</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default AddWR;
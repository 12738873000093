import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { Chart } from "react-google-charts";
import { tokens } from '../theme';


const PieChart = ({ data, colors }) => {
  const theme = useTheme();
  const color = tokens(theme.palette.mode);
  const options = {
    title: "",
    pieHole: 0.4, // Creates a Donut Chart. Does not do anything when is3D is enabled
    is3D: true, // Enables 3D view
    pieStartAngle: 100, // Rotates the chart
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        color:  theme.palette.mode == 'dark' ? "#fff" : "#000",
        fontSize: 14,
      },
    },
    colors: colors ?? ["#8AD1C2", "#9F8AD1", "#D18A99", "#BCD18A", "#D1C28A"],
    backgroundColor: "transparent"
  };
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"400px"}
    />
  );
};
PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PieChart;


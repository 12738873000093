import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { assetModel } from "../../schema/schema.js";
import { apiService, apiService2 } from '../../features/apiService';
import { getAssets, getConfig } from '../../features/helpers/genhelper';
import { formCreator } from "../../features/helpers/formhelper";

// API End Point
const endPoint = myConfig.addAssetEP;
let fakecount = 0;

const AddAsset = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(assetModel)
  const [modAssetList, setAssets] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({});
  const [facilityList, setFacilityList] = useState([]);
  const [familyList, setFamilyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [unitConfig, setUnitConfig] = useState('');
  const [assetList, setAssetList] = useState([]);
  let updAssetList = [];

  if (props.open.state & !open) {
    setOpen(true);
    getInitialValues();
  }

  function getInitialValues() {
    let ivalues = {};
    for (const [key, value] of Object.entries(assetModel)) {
      if (value.hasOwnProperty("fkey")) {
        if (!value["fkey"]) { ivalues[value['field']] = ""; }
      } else {
        ivalues[value['field']] = "";
      }
    }
    ++fakecount;
    setInitialValues(ivalues)
    return ivalues;
  }

  useEffect(() => {
    getAssets(user).then(resp => {
      setAssetList(resp[0]);
    });
  }, [])

  useEffect(() => {
    apiService2('POST', myConfig.getFacilitiesFilterEP, {},)
      .then(response => {
        setFacilityList(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  let modSectionList = [];
  for (const fac of facilityList.filter(x => x.type == 'Section')) {
    modSectionList.push(fac.name);
  }

  useEffect(() => {
    apiService2('GET', myConfig.getAssetfamily, {},)
      .then(response => {
        setFamilyList(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  let modFamilyList = [];
  for (const fam of familyList) {
    modFamilyList.push(fam.name);
  }

  useEffect(() => {
    getConfig(user,'Unit').then(resp => setUnitConfig(resp));
  }, [open])

  useEffect(() => {
    apiService2('GET', myConfig.getUnitEP)
      .then(response => {
        setUnitList(response.data)
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);


  let modUnitList = [];
  for (const fac of unitList.filter(x => x.unitType == 'Meter')) {
    modUnitList.push(fac.parameter);
  }

  



  const valueDB = { "parentassetid": modAssetList, "facility": facilityList, "section": modSectionList, "subsection": [], "family": modFamilyList, "unit": modUnitList };


  // Handle States for the dialog box
  function handleClose() {
    // console.log("handleClose: Setting open to false");
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }


  function handleSelect(key, value) {
    if (key == 'family') {
      let fields = formFields.filter(x => !x.dynamicfiled);
      let family = familyList.find(x => x.name == value);
      if (family?.assetfamilyList.length > 0) {
        family?.assetfamilyList.forEach(res => {
          fields.push({ field: res.fieldname, type: String, required: false, label: res.fieldname, dynamicfiled: true })
        })
      }
      setFormFields([...fields])
    } else if (key == 'section') {
      let values = [];
      values = valueDB['facility']
      values = values.filter(x => x.parent == value);
      let finalSubSection = [];
      values.forEach(res => {
        finalSubSection.push(res.name)
      })
      valueDB['subsection'] = finalSubSection;
      let asset = assetList.filter(x => x.section == value);
      asset = asset.map(x => x.assetid);
      valueDB['parentassetid'] = asset;
    } 
  }


  return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Add Asset</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                enableReinitialize
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  values['tenantid'] = user.tenantid;
                  var fileds = formFields.filter(x => x.dynamicfiled);
                  var specifications = [];
                  fileds.forEach(res => {
                    specifications.push({ fieldname: res.field, values: values[res.field] })
                  })
                  values['specifications'] = specifications;
                  values['approval'] = values['approval'] == 'Yes' ? true : false;
                  values['reset'] = values['reset'] == 'Yes' ? true : false;
                  values['iiotenabled'] = values['iiotenabled'] == 'Yes' ? true : false;
                  values['inspectionState'] = 'Green';
                  var family = familyList.find(x => x.name == values['family']);
                  values['assetfamilyid'] = family._id;
                  if(values['parentassetid'] != null && values['parentassetid'] != '') {
                    var parent = assetList.find(x => x.assetid == values['parentassetid']);
                    if(parent.assettype == 'Logical-C' || parent.assettype == 'Logical-D' || parent.logicalid){
                      values['logicalid'] = parent.logicalid != null ? parent.logicalid : parent._id;
                    }
                  }
                  apiService(endPoint, values)
                    .then(response => {
                      console.log("Response->", response);
                      setSubmitionCompleted(true);
                    })
                    .catch(error => {
                      console.error("Asset Add Failed->", error);
                      setSubmitionError(true);
                      setSubmitionCompleted(true);
                    })
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
                  assetid: Yup.string().required("Required"),
                  name: Yup.string().required("Required"),
                  desc: Yup.string().required("Required"),
                  family: Yup.string().required("Required"),
                  state: Yup.string().required("Required"),
                  assettype: Yup.string().required("Required"),
                  assetclass: Yup.string().required("Required"),
                  section: Yup.string().required("Required"),
                  approval: Yup.string().required("Required"),
                  meter: Yup.string().required("Required"),
                  iiotenabled: Yup.string().required("Required"),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    resetForm,
                    setFieldValue
                  } = props;
                  return (
                    <form onSubmit={handleSubmit} >
                      <Box >
                        <Grid container spacing={2}>
                          {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue)}
                        </Grid>
                      </Box>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Asset Added</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Add Asset Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Try Again Later</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
  );
}

export default AddAsset;
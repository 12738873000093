import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Login from "./scenes/login";
import Register from "./scenes/register";
import Corp from "./scenes/corp";
import Team from "./scenes/team";
import Facilities from "./scenes/facilities";
import Assets from "./scenes/eamassets";
import Departments from "./scenes/departments";
import MaintenanceLib from "./scenes/maintenancelib";
import InspectionLib from "./scenes/inspectionlib";
import Inventory from "./scenes/inventory";
import Maintenance from "./scenes/maintenance";
import WorkOrder from "./scenes/workorder";
import WorkRequest from "./scenes/workrequest";
import Analytics from "./scenes/analytics";
import Reports from "./scenes/reports";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import { useNavigate } from 'react-router-dom';
import FailurePrevention from "./scenes/failureprevention";
import Resource from "./scenes/resource";
import Setup from "./scenes/setup";
import Store from "./scenes/store";
import Procurement from "./scenes/procurement";
import ViewWorkOrder from "./scenes/workorder/viewwo";
import StoreView from "./scenes/store";
import SensorData from "./scenes/sensordata";
import ForgotPassword from "./scenes/forgot";
import ResetPasword from "./scenes/forgot/resetpassword";
import CreatePassword from "./scenes/forgot/createPassword";
import ApprovalScreen from "./scenes/approval";
import Workshop from "./scenes/workshop";
import ChangePasword from "./scenes/changepassword";
import Profile from "./scenes/profile";

function PageNotFound() {
  return (
    <div>
      <h2>404 Page not found</h2>
    </div>
  );
}

function App() {
  const [theme, colorMode] = useMode();
  const { user } = useSelector((state) => { console.log("apps user updated->", state.auth); return state.auth })
  const [isSidebar, setIsSidebar] = useState(user ? true : false);
  const navigate = useNavigate();
  localStorage.setItem('mode', theme.palette.mode);
  useEffect(() => {
    console.log("Checking user")
    if (!user) {
      setIsSidebar(false);
    } else { setIsSidebar(true); }
  }, [user])

  const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
    const { user } = useSelector((state) => { return state.auth })
    //console.log(["App.js: RequireAuth User->", user]);
    if (!user) {
      console.log("Timeout: User Logged out or never logged in");
      //navigate("/login");
      return (<Login />);
    }
    return children;
  };
  //console.log(["App.js: user->", user, isSidebar]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {user && (<>
              {isSidebar && <Sidebar />}
              <main className="content">
                {isSidebar && <Topbar />}
                <Box className="content-body">
                  <Routes>
                    <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
                    <Route path="/corp" element={<Corp />} />
                    <Route path="/team" element={<RequireAuth><Team /></RequireAuth>} />
                    <Route path="/assets" element={<RequireAuth><Assets /></RequireAuth>} />
                    <Route path="/facilities" element={<RequireAuth><Facilities /></RequireAuth>} />
                    <Route path="/departments" element={<RequireAuth><Departments /></RequireAuth>} />
                    <Route path="/inventory" element={<RequireAuth><Inventory /></RequireAuth>} />
                    <Route path="/store" element={<RequireAuth><StoreView /></RequireAuth>} />
                    <Route path="/mainlib" element={<RequireAuth><MaintenanceLib /></RequireAuth>} />
                    <Route path="/inspectionlib" element={<RequireAuth><InspectionLib /></RequireAuth>} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/line" element={<Line />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/geography" element={<Geography />} />
                    <Route path="/maintenance" element={<RequireAuth><Maintenance /></RequireAuth>} />
                    <Route path="/workorder" element={<RequireAuth><WorkOrder /></RequireAuth>} />
                    <Route path="/workorder/view/:id" element={<RequireAuth><ViewWorkOrder /></RequireAuth>} />
                    <Route path="/failurepreventation" element={<RequireAuth><FailurePrevention /></RequireAuth>} />
                    <Route path="/workrequest" element={<RequireAuth><WorkRequest /></RequireAuth>} />
                    <Route path="/analytics" element={<RequireAuth><Analytics /></RequireAuth>} />
                    <Route path="/reports" element={<RequireAuth><Reports /></RequireAuth>} />
                    <Route path="/resource" element={<RequireAuth><Resource /></RequireAuth>} />
                    <Route path="/setup" element={<RequireAuth><Setup /></RequireAuth>} />
                    <Route path="/procurement" element={<RequireAuth><Procurement /></RequireAuth>} />
                    <Route path="/sensordata" element={<RequireAuth><SensorData /></RequireAuth>} />
                    <Route path="/workshop" element={<RequireAuth><Workshop /></RequireAuth>} />
                    <Route path="/approval/:id/:tenantid/:user/:level" element={<ApprovalScreen />} />
                    <Route path="/change-password" element={<RequireAuth><ChangePasword /></RequireAuth>} />
                    <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
                    <Route path="*" element={<Login />} />
                  </Routes>
                </Box>
              </main>
            </>)}
            {!user && <Routes>
              <Route path="/login" element={<Login />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/resetpassword/:id" element={<ResetPasword />} />
              <Route path="/createPassword/:id" element={<CreatePassword />} />
              <Route path="/approval/:id/:tenantid/:user/:level" element={<ApprovalScreen />} />
              <Route path="*" element={<Login />} />
            </Routes>}
            <ToastContainer autoClose={2200} />
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { FormHelperText, Grid, IconButton, InputLabel, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import {
    Button,
    withStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { assetfamilyModel } from "../../schema/schema";
import { apiService2 } from "../../features/apiService";

import { toast } from "react-toastify";
import { getConfig } from "../../features/helpers/genhelper";

// API End Point

let fakecount = 0;
const AddAssetFamily = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('');
    const [assetFamilyList, setAssetFamilyList] = useState([])
    const [unitList, setUnitList] = useState([]);
    const [familyName, setFamilyName] = useState('');
    const [familyCode, setFamilyCode] = useState('');
    const [familyDesc, setFamilyDesc] = useState('');
    const [initialValues, setInitialValues] = useState({});
    const [unitConfig, setUnitConfig] = useState('');



    function getInitialValues() {
        let ivalues = {};
        for (const item of assetfamilyModel) {
            ivalues[item.field] = '';
        }
        fakecount++;
        setInitialValues({ ...ivalues })
    }
    if (props.open.state & !open) {
        setOpen(true);
        setAssetFamilyList([])
        setFamilyName('');
        setFamilyCode('');
        setFamilyDesc('');
        getInitialValues();
    }

    useEffect(() => {
        getConfig(user, 'Unit').then(resp => setUnitConfig(resp));
    }, [open])

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }

    useEffect(() => {
        apiService2('GET', myConfig.getUnitEP)
            .then(response => {
                console.log(response);
                setUnitList(response.data)
            })
            .catch(error => {
                console.error(error);
            })
    }, [open]);

    let modUnitList = [];
    for (const unitItem of unitList) {
        if (unitItem.unitType == 'Measurement') {
            console.log('unitConfig', unitConfig);
            modUnitList.push(unitItem.uom[unitConfig]);
        }

    }


    function handleSave() {
        if (familyName != null && familyCode != null && familyCode != null && familyName.length > 0 && assetFamilyList.length > 0) {
            let details = { name: familyName, code: familyCode, description: familyDesc, assetfamilyList: assetFamilyList }
            apiService2('POST', myConfig.addAssetfamily, details)
                .then(resp => {
                    if (resp.data.message) {
                        setErrorMessage(resp.data.message);
                        setSubmitionError(true);
                    } else {
                        setSubmitionCompleted(true);
                    }
                })
                .catch(error => {
                    console.log(["Asset Family Add Failed->", error]);
                    setSubmitionError(true);
                    setSubmitionCompleted(true);
                });
        } else {
            toast.warning("Please add the family name, code and family list.");
        }
    }



    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Add Asset Family</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <InputLabel id="simple-label">Asset Family Name *</InputLabel>
                                    <TextField
                                        id="outlined-basic"
                                        placeholder={'Asset Family Name'}
                                        name={'familyName'}
                                        style={{ width: "100%", marginTop: "2px" }}
                                        margin="normal"
                                        type="text"
                                        value={familyName}
                                        onChange={(e) => setFamilyName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel id="simple-label">Asset Family Code *</InputLabel>
                                    <TextField
                                        id="outlined-basic"
                                        placeholder={'Asset Family Code'}
                                        name={'familyName'}
                                        style={{ width: "100%", marginTop: "2px" }}
                                        margin="normal"
                                        type="text"
                                        value={familyCode}
                                        onChange={(e) => setFamilyCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel id="simple-label">Asset Family Desc *</InputLabel>
                                    <TextField
                                        id="outlined-basic"
                                        placeholder={'Asset Family Desc'}
                                        name={'familyName'}
                                        style={{ width: "100%", marginTop: "2px" }}
                                        margin="normal"
                                        type="text"
                                        value={familyDesc}
                                        onChange={(e) => setFamilyDesc(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                key={fakecount}
                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setSubmitting(true);
                                    var dataList = assetFamilyList;
                                    dataList.push({ "fieldname": values['fieldname'], "description": values['description'], "datatype": values['datatype'], "units": values['units'] });
                                    setAssetFamilyList([...dataList]);
                                    getInitialValues();
                                }}
                                validationSchema={Yup.object().shape({
                                    fieldname: Yup.string().required("Field Name is required"),
                                    datatype: Yup.string().required("Data Type is required"),
                                })}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                        resetForm
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                {(() => {
                                                    let result = [];

                                                    for (const item of assetfamilyModel.filter(x => x.skip == false)) {
                                                        var key = item.field;
                                                        var enumList = [];
                                                        enumList = item.enum;
                                                        if (item.field == 'units') {
                                                            enumList = modUnitList;
                                                        }
                                                        if (item.isLable) {
                                                            result.push(
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5" component="h5">
                                                                        {item.label}
                                                                    </Typography>
                                                                </Grid>)
                                                        } else if (item.select) {
                                                            if (item.condition) {
                                                                if(values[item.conditionField] == item.conditionValue) {
                                                                    result.push(
                                                                        <Grid item xs={6}>
                                                                            <InputLabel id="simple-label">{item.label}</InputLabel>
                                                                            <TextField
                                                                                id="outlined-basic"
                                                                                placeholder={item.label}
                                                                                name={key}
                                                                                value={values[key]}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                margin="normal"
                                                                                type="text"
                                                                                select
                                                                                defaultValue={''}
                                                                                error={errors[key] && touched[key]}
                                                                                sx={{ display: 'flex', width: "100%", marginTop: "2px" }}>
                                                                                {enumList.map((option) => (
                                                                                    <MenuItem key={option} value={option}>
                                                                                        {option}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                                                                        </Grid>)
                                                                }
                                                            } else {
                                                                result.push(
                                                                    <Grid item xs={6}>
                                                                        <InputLabel id="simple-label">{item.label}</InputLabel>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            placeholder={item.label}
                                                                            name={key}
                                                                            value={values[key]}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            margin="normal"
                                                                            type="text"
                                                                            select
                                                                            defaultValue={''}
                                                                            error={errors[key] && touched[key]}
                                                                            sx={{ display: 'flex', width: "100%", marginTop: "2px" }}>
                                                                            {enumList.map((option) => (
                                                                                <MenuItem key={option} value={option}>
                                                                                    {option}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                        <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                                                                    </Grid>)
                                                            }
                                                        } else {
                                                            result.push(
                                                                <Grid item xs={6}>
                                                                    <InputLabel id="simple-label">{item.label}</InputLabel>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        placeholder={item.label}
                                                                        name={key}
                                                                        style={{ width: "100%", marginTop: "2px" }}
                                                                        margin="normal"
                                                                        type="text"
                                                                        value={values[key]}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors[key] && touched[key]}
                                                                    />
                                                                    <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                                                                </Grid>
                                                            )
                                                        }
                                                    }
                                                    return result;
                                                })()}
                                            </Grid>
                                            <DialogActions>
                                                <Button
                                                    type="button"
                                                    variant="contained" color="primary"
                                                    onClick={() => resetForm()}
                                                    disabled={!dirty || isSubmitting}>
                                                    Reset
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting} >
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ textAlign: "left" }}>
                                            <TableCell>Field Name</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Data Type</TableCell>
                                            <TableCell>Units</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {assetFamilyList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.fieldname}
                                                </TableCell>
                                                <TableCell> {row.description}</TableCell>
                                                <TableCell> {row.datatype}</TableCell>
                                                <TableCell> {row.units}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="button" variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </DialogActions>

                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks! Asset Family Added</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Thanks</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add Asset Family Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default AddAssetFamily;
import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  FormHelperText,
  Autocomplete,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DateTimePicker } from "@mui/x-date-pickers";
import { uploadFile } from "./awsupload";

function getDate(datestr) {
  if (datestr === '' || datestr === null) return '';
  var newdate = new Date(datestr);
  return newdate.toLocaleDateString();
}

/** 
 * Summary. Create a Form using the schema 
 * @param {string} type - Values <simple, complex>
 * @param {string} processType - Values <add, edit, view>
 */
export const formCreator = (type, processType, formFields, startValues, values = {}, handleChange = null, handleBlur = null, touched = null, errors = null, valueDB = null, handleSelect = null, addrequired = false, setFieldValue = null, gridWidth = 4) => {
  let result = [];
  let skipflag = false;
  let edit = true;
  let noeditflag = false;
  let reqdflag = false;
  let usename = '';
  let labelvalue = '';
  let enumlist = [];
  let textwidth = 150;
  let textheight = 0;
  let multilineflag = false;
  let totalrows;
  let displayflag = false;
  let viewflag = true;
  let defaultvalue = "";
  formFields.forEach((vitem) => {
    let key = vitem['field'];
    let label = vitem['label'];
    skipflag = false;
    noeditflag = false;
    reqdflag = false;
    textwidth = '100%';
    totalrows = 1
    multilineflag = false;
    displayflag = false;
    textheight = 0;
    defaultvalue = vitem.defaultValue ? vitem.defaultValue : "";
    viewflag = vitem.hasOwnProperty('view') ? vitem['view'] : true;
    if (startValues.hasOwnProperty(key)) defaultvalue = startValues[key];
    if (processType === "view" && !viewflag) return;
    if (vitem.hasOwnProperty("required")) { labelvalue = vitem.required ? vitem.label + "*" : vitem.label } else { labelvalue = vitem.label; }
    if (vitem.isLable) {
      result.push(
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            {vitem.label}
          </Typography>
        </Grid>
      )
    } else if (type === "simple") {
      result.push(
        <Grid item xs={gridWidth}>
          <TextField
            id={key}
            label={label}
            name={key}
            margin="normal"
            type="text"
            InputProps={{
              readOnly: true,
            }}
            onChange={handleChange}
            sx={{ display: 'grid', width: "100%" }}
            defaultValue={defaultvalue}
          />
        </Grid>
      )
    } else if (key === "fileUrl" || key === "videoUrl" || key === "imageUrl") {
      labelvalue =
        result.push(
          <Grid item xs={gridWidth}>
            <InputLabel id="simple-label">{labelvalue}</InputLabel>
            <TextField
              id={key}
              name={key}
              margin="normal"
              type="file"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <input type="file"
                    accept={`${vitem['format']}`}
                    onChange={async (event) => {
                      const file = event.target.files[0];
                      var data = await uploadFile(file);
                      setFieldValue(key, data.Location);
                    }} />)
              }}

              sx={{ width: "100%", marginTop: 0 }}
              defaultValue={defaultvalue}
            />
          </Grid>
        )
    } else {
      if (vitem.hasOwnProperty("skip")) {
        if (vitem["skip"]) skipflag = true;
      }
      if (vitem.hasOwnProperty("fkey")) {
        if (vitem["fkey"]) skipflag = true;
      }
      if (vitem.hasOwnProperty("edit") && processType == 'edit') {
        if (!vitem["edit"]) {
          noeditflag = true;
          skipflag = true;
        }
      }
      if (vitem.hasOwnProperty("required")) {
        if (vitem["required"] && addrequired) reqdflag = true;
      }
      if (vitem.hasOwnProperty("width")) {
        textwidth = vitem["width"];
      }
      if (vitem.hasOwnProperty("display")) {
        displayflag = vitem["display"];
        if (!displayflag) defaultvalue = "";
      }
      if (vitem.hasOwnProperty("multiline")) {
        multilineflag = vitem["multiline"];
        totalrows = 4;
        textheight = 40;
      }
      usename = key;
      if (vitem.hasOwnProperty("alt")) {
        usename = vitem.alt;
      }
      if (key && key.toLowerCase().indexOf("date") >= 0) {
        defaultvalue = (defaultvalue !== undefined && defaultvalue !== '' && defaultvalue !== null) ? defaultvalue.substring(0, 10) : "";
      }
      if ((!vitem.hasOwnProperty("venum") && !skipflag)) {
        if (vitem.condition) {
          if (values[vitem.conditionField] == vitem.conditionValue) {
            if (key && (key.toLowerCase().indexOf("startdate") >= 0 || key == 'finishdate' || key == 'startdate' || key === 'finishrequestdate')) {
              let date = values[key] ? new Date(values[key]) : new Date();
              let maxDate = new Date();
              let today = new Date();
              if (values[key] == null) setFieldValue(key, date);
              if (key == 'dob') {
                maxDate = dayjs((today.getFullYear() - 18) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
              } else if (date > maxDate) {
                maxDate = dayjs((date.getFullYear()) + '-' + (date.getMonth() + 1) + '-' + date.getDate())
              } else {
                maxDate = dayjs((today.getFullYear()) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
              }
              console.log("<0> finishrequestdate reached");
              if (key === 'finishrequestdate') {
                
                maxDate = dayjs((today.getFullYear() + 1) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
              }
              result.push(
                <Grid item xs={gridWidth}>
                  <React.Fragment>
                    <InputLabel id="simple-label">{labelvalue}</InputLabel>
                    <DateTimePicker
                      sx={{ display: 'flex', flexdirection: 'row', width: "100%" }}
                      onBlur={handleBlur}
                      margin="normal"
                      value={dayjs(date)}
                      maxDate={maxDate}
                      error={errors[key] && touched[key]}
                      onChange={(newValue) => {
                        setFieldValue(key, newValue)
                      }}
                    />
                    <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                  </React.Fragment>
                </Grid>
              );
            } else if (key && key.toLowerCase().indexOf("date") >= 0 || key == 'dob') {
              console.log("ALT 1 = finishrequestdate reached");
              let date = values[key] ? new Date(values[key]) : new Date();
              let maxDate = new Date();
              let today = new Date();
              if (values[key] == null) setFieldValue(key, date);
              if (key == 'dob') {
                maxDate = dayjs((today.getFullYear() - 18) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
              } else {
                maxDate = dayjs((today.getFullYear()) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
              }
              result.push(
                <Grid item xs={gridWidth}>
                  <React.Fragment>
                    <InputLabel id="simple-label">{labelvalue}</InputLabel>
                    <DatePicker
                      id={key}
                      error={errors[key] && touched[key]}
                      name={key}
                      value={dayjs(date)}
                      maxDate={vitem.isFuture ? null : maxDate}
                      //minDate={vitem.isFuture ? maxDate : null}
                      selected={values.key || null}
                      onChange={date => setFieldValue(key, date)}
                      sx={{ display: 'flex', flexdirection: 'row', width: "100%" }}
                      onBlur={handleBlur}
                      margin="normal"
                      helperText={errors[key] && touched[key] ? errors[key] : ""}
                    />
                    <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                  </React.Fragment>
                </Grid>)
            } else {
              result.push(
                <Grid item xs={gridWidth}>
                  <InputLabel id="simple-label">{labelvalue}</InputLabel>
                  <TextField
                    id={key}
                    error={errors[key] && touched[key]}
                    placeholder={labelvalue}
                    name={key}
                    value={values[key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    type="text"
                    style={textheight > 0 ? { width: '100%', marginTop: 0 } : { width: '100%', marginTop: 0 }}
                    defaultValue={defaultvalue}
                    InputProps={{
                      readOnly: noeditflag,
                    }}
                    multiline
                    rows={totalrows}
                  />
                  <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                </Grid>
              )
            }
          }
        } else {
          if (key && (key.toLowerCase().indexOf("startdate") >= 0 || key == 'finishdate' || key == 'startdate' || key === 'finishrequestdate')) {
            console.log("finishrequestdate reached->", key);
            let date = values[key] ? new Date(values[key]) : new Date();
            let maxDate = new Date();
            let today = new Date();
            if (values[key] == null) setFieldValue(key, date);
            if (key == 'dob') {
              maxDate = dayjs((today.getFullYear() - 18) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
            } else if (date > maxDate) {
              maxDate = dayjs((date.getFullYear()) + '-' + (date.getMonth() + 1) + '-' + date.getDate())
            } else {
              maxDate = dayjs((today.getFullYear()) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
            }
            if (key === 'finishrequestdate') {    
              maxDate = dayjs((today.getFullYear() + 1) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
            }
            result.push(
              <Grid item xs={gridWidth}>
                <React.Fragment>
                  <InputLabel id="simple-label">{labelvalue}</InputLabel>
                  <DateTimePicker
                    sx={{ display: 'flex', flexdirection: 'row', width: "100%" }}
                    onBlur={handleBlur}
                    margin="normal"
                    value={dayjs(date)}
                    maxDate={maxDate}
                    error={errors[key] && touched[key]}
                    onChange={(newValue) => {
                      setFieldValue(key, newValue)
                    }}
                  />
                  <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                </React.Fragment>
              </Grid>
            );
          } else if (key && (key.toLowerCase().indexOf("date") >= 0 || key == 'dob')) {
            console.log("ALT 2 = finishrequestdate reached");
            let date = values[key] ? new Date(values[key]) : new Date();
            let maxDate = new Date();
            let today = new Date();
            if (values[key] == null) setFieldValue(key, date);
            if (key == 'dob') {
              maxDate = dayjs((today.getFullYear() - 18) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
            } else {
              maxDate = dayjs((today.getFullYear()) + '-' + (today.getMonth() + 1) + '-' + today.getDate())
            }
            result.push(
              <Grid item xs={gridWidth}>
                <React.Fragment>
                  <InputLabel id="simple-label">{labelvalue}</InputLabel>
                  <DatePicker
                    id={key}
                    error={errors[key] && touched[key]}
                    name={key}
                    value={dayjs(date)}
                    maxDate={maxDate}
                    selected={values.key || null}
                    onChange={date => setFieldValue(key, date)}
                    sx={{ display: 'flex', flexdirection: 'row', width: "100%" }}
                    onBlur={handleBlur}
                    margin="normal"
                    helperText={errors[key] && touched[key] ? errors[key] : ""}
                  />
                  <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                </React.Fragment>
              </Grid>)
          } else {
            result.push(
              <Grid item xs={gridWidth}>
                <InputLabel id="simple-label">{labelvalue}</InputLabel>
                <TextField
                  id={key}
                  error={errors[key] && touched[key]}
                  placeholder={labelvalue}
                  name={key}
                  value={values[key]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  type="text"
                  style={textheight > 0 ? { width: "100%", marginTop: 0 } : { width: "100%", marginTop: 0 }}
                  defaultValue={defaultvalue}
                  InputProps={{
                    readOnly: noeditflag,
                  }}
                  multiline
                  rows={totalrows}
                />
                <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
              </Grid>
            )
          }
        }
        if (vitem.hasOwnProperty("subfield")) {
          let skey = vitem['subfield']
          result.push(
            <Grid item xs={gridWidth}>
              <TextField
                id={key}
                error={errors[key] && touched[key]}
                label={skey}
                name={skey}
                value={values.skey}
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                type="text"
                sx={{ display: 'flex', width: '100%' }}
              />
              <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
            </Grid>
          )
        }
      } else if (!skipflag) {
        enumlist = vitem.venum ? vitem.venum : [];
        if (vitem.hasOwnProperty("required")) { labelvalue = vitem.required ? label + "*" : label } else labelvalue = label;
        if (vitem.hasOwnProperty("value")) {
          if (vitem.value) {
            enumlist = valueDB[usename] ? valueDB[usename] : [];
          }
        }
        if (vitem.condition) {
          if (vitem.multipleCondition) {
            var isView = false;
            for (var i = 0; i < vitem.conditionField.length; i++) {
              if (values[vitem.conditionField[i].condition] == vitem.conditionField[i].conditionValue) {
                isView = true;
                break;
              }
            }
            if (isView) {
              result.push(
                <Grid item xs={gridWidth}>
                  <React.Fragment>
                    <InputLabel id="simple-label">{labelvalue}</InputLabel>
                    <Select
                      id="outlined-basic"
                      error={errors[key] && touched[key]}
                      name={key}
                      defaultValue={defaultvalue}
                      onChange={(e) => {
                        handleChange(e)
                        handleSelect(key, e.target.value)
                      }}
                      multiple={vitem.hasOwnProperty("multiple") ? vitem.multiple : false}
                      value={values[key]}
                      onBlur={handleBlur}
                      margin="normal"
                      sx={{ display: 'flex', width: '100%' }}>
                      {enumlist.map((ivalue) => (
                        <MenuItem key={ivalue} value={ivalue}>
                          {ivalue}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                  </React.Fragment>
                </Grid>
              )
            }
          } else {
            if (values[vitem.conditionField] == vitem.conditionValue) {
              result.push(
                <Grid item xs={gridWidth}>
                  <React.Fragment>
                    <InputLabel id="simple-label">{labelvalue}</InputLabel>
                    <Select
                      id="outlined-basic"
                      error={errors[key] && touched[key]}
                      name={key}
                      defaultValue={defaultvalue}
                      onChange={(e) => {
                        handleChange(e)
                        handleSelect(key, e.target.value)
                      }}
                      multiple={vitem.hasOwnProperty("multiple") ? vitem.multiple : false}
                      value={values[key]}
                      onBlur={handleBlur}
                      margin="normal"
                      sx={{ display: 'flex', width: '100%' }}>
                      {enumlist.map((ivalue) => (
                        <MenuItem key={ivalue} value={ivalue}>
                          {ivalue}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                  </React.Fragment>
                </Grid>
              )
            }
          }
        } else {
          if (noeditflag) { enumlist = [defaultvalue] ? [defaultvalue] : [] }
          if (vitem.hasOwnProperty("multiple") && vitem.multiple) {
            const [multiSelectValue, setMultiSelectValue] = useState([]);
            if (multiSelectValue.length == 0 && processType == 'edit' && values[key]?.length > 0) {
              setMultiSelectValue(values[key])
            }
            result.push(
              <Grid item xs={gridWidth}>
                <React.Fragment>
                  <InputLabel id="demo-simple-select-label">{labelvalue}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id={key}
                    label={labelvalue}
                    name={key}
                    value={values[key]}
                    error={errors[key] && touched[key]}
                    onBlur={handleBlur}
                    multiple
                    renderValue={(selected) => selected.join(', ')}
                    onChange={e => {
                      if (e.target.value?.includes('All')) {
                        handleSelect(key, enumlist)
                        var multi = enumlist
                        setMultiSelectValue(multi)
                      } else {
                        handleChange(e)
                        handleSelect(key, e.target.value)
                        var mult = e.target.value
                        setMultiSelectValue(mult)
                      }
                    }}
                    margin="normal"
                    type="text"
                    select
                    defaultValue={defaultvalue}
                    sx={{ display: 'flex', width: "100%", marginTop: 0 }}>
                    {enumlist.map((option) => (
                      <MenuItem key={option} value={option}>
                        <Checkbox checked={multiSelectValue?.indexOf(option) > -1} />
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                </React.Fragment>
              </Grid>
            )
          } else {
            result.push(
              <Grid item xs={gridWidth}>
                <React.Fragment>
                  <InputLabel id="simple-label">{labelvalue}</InputLabel>
                  <Select
                    id={key}
                    error={errors[key] && touched[key]}
                    label={labelvalue}
                    name={key}
                    defaultValue={defaultvalue}
                    onChange={(e) => {
                      handleChange(e)
                      handleSelect(key, e.target.value)
                    }}
                    onBlur={handleBlur}
                    multiple={vitem.hasOwnProperty("multiple") ? vitem.multiple : false}
                    value={values[key]}
                    margin="normal"
                    sx={{ display: 'flex', width: '100%' }}>
                    {enumlist?.map((ivalue) => (
                      <MenuItem key={ivalue} value={ivalue}>
                        {ivalue}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: 'red', fontSize: '15px' }}>{errors[key] && touched[key] ? errors[key] : ""}</FormHelperText>
                </React.Fragment>
              </Grid>
            )
          }
        }
      } else if (noeditflag) {
        result.push(
          <Grid item xs={gridWidth}>
            <InputLabel id="simple-label">{labelvalue}</InputLabel>
            <TextField
              id={key}
              placeholder={label}
              value={values[key]}
              name={key}
              margin="normal"
              type="text"
              InputProps={{
                readOnly: true,
              }}
              onChange={handleChange}
              sx={{ width: "100%", marginTop: 0, }}
              defaultValue={defaultvalue}
            />
          </Grid>
        )
      }
    }
  })
  return result;
}

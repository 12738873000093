import { useTheme } from "@mui/material";
import { Chart } from "react-google-charts";
//import { BarChart } from '@mui/x-charts/BarChart';
//import { axisClasses } from "@mui/x-charts";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, LabelList } from 'recharts';

/*
const BarCharts = ({ data }) => {
  const theme = useTheme();
  const options = {
    title: "",
    chartArea: { width: "100%", height: "100%" },
    legend: {
      position: "bottom",
      alignment: "center",
    },
    backgroundColor: ['transparent'],
    colors: ['#8AD112',],
   sx: {
    
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "rotate(-50deg) translate(0px, -40px)"
    },
    [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
      transform: "rotateZ(-90deg) translate(-130px, 10px)"
    }
  }
  };

  console.log("data received->", data);

  return (
    <BarChart
      dataset = {data.series}
      xAxis={[{ scaleType: 'band', dataKey: [data.xlabel], tickLabelInterval: () => true}]}
      series={[{ dataKey: 'wocount', label: 'WO Count'}]}
      width={500}
      height={300}
      margin={{ top: 25, right: 30, bottom: 150, left: 25 }}
      slotProps={{ legend: { hidden: true } }}
      {...options}
    />
  );
};
  */

const BarCharts = ({ data }) => {
  console.log("data received->", data);
return (
  <ResponsiveContainer width={"100%"} height={300}>
  <BarChart
    data={data.series}
    margin={{ top: 5, right: 40, bottom: 150, left: 2 }}
  >
    
    <XAxis dataKey={data.xlabel} angle='70' interval={0} textAnchor="start"  >
      <Label  position="center" />
    </XAxis>
    <YAxis />
    <Tooltip />
    <Bar
      dataKey="wocount"
      fill="#8884d8"
      activeBar={<Rectangle fill="pink" stroke="black" />}>
        <LabelList dataKey="wocount" position="top" />
      </Bar>
    
 
  </BarChart>
</ResponsiveContainer>
);
  };




export default BarCharts;

import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { inspectionAspectsModel } from "../../schema/schema";
import { apiService, apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { toast } from "react-toastify";

let fakecount = 0;
const EditAspect = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.auth);
    const [open, setOpen] = useState(props.open.state);
    const [initialValues, setInitialValues] = useState({});
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isMessageView, setMessageView] = useState(false);
    const [maxWidth, setMaxWidth] = useState('md');
    const [deptList, setDeptList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [measuredValue, setMeasuredValue] = useState({ greenCategory: '', green: '', yellowMin: '', yellowMinLabel: '', yellowMax: '', redMin: '', redMinLabel: '', redMax: '' });

    var enumList = ['Greater than Equal', 'Less than Equal'];
    function getInitialValues() {
        let ivalues = {};
        const Aspect = props.open.aspect;
        for (const item of inspectionAspectsModel) {
            ivalues[item.field] = Aspect[item.field];
        }
        let yellowMinLabel, redMinLabel;
        if (Aspect['greenCategory'] == 'Greater than Equal') {
            yellowMinLabel = 'Less than ' + Aspect['yellowMin']
            redMinLabel = 'Less than ' + Aspect['redMin']
        } else {
            yellowMinLabel = 'Greater than ' + Aspect['yellowMin']
            redMinLabel = 'Greater than ' + Aspect['redMin']
        }
        let datas = { greenCategory: Aspect['greenCategory'], green: Aspect['green'], yellowMin: Aspect['green'], yellowMinLabel: yellowMinLabel, yellowMax: Aspect['yellowMax'], redMin: Aspect['redMin'], redMinLabel: redMinLabel, redMax: Aspect['redMax'] }
        setMeasuredValue(datas)
        setInitialValues({ ...ivalues })
        return ivalues;
    }

    useEffect(() => {
        apiService(myConfig.getDeptEP)
            .then(response => {
                setDeptList(response.data)
                if (response.data.length == 0) {
                    toast.error("Department list Empty, Please add department first");
                }
            })
            .catch(error => {
                console.error(error);
            })
    }, []);


    let modDeptList = [];
    for (const deptItem of deptList) {
        modDeptList.push(deptItem.name);
    }

    useEffect(() => {
        apiService2('GET', myConfig.getUnitEP)
            .then(response => {
                setUnitList(response.data)
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    let modUnitList = [];

    for (const unit of unitList.filter(x => x.unitType == 'Measurement')) {
        modUnitList.push(unit.parameter);
    }

    if (props.open.state & !open) {
        setOpen(true);
        setMaxWidth('lg');
        getInitialValues();
    }

    const handleClose = () => {
        setOpen(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    };
    function handleValue(values, event, index, type) {
        console.log(event.target.value);
        if (values['greenCategory'] == 'Greater than Equal') {
            if (index == 1) {
                measuredValue['green'] = event.target.value;
                measuredValue['yellowMin'] = event.target.value;
                measuredValue['yellowMinLabel'] = 'Less than ' + event.target.value;
                measuredValue['yellowMax'] = '';
                measuredValue['redMin'] = '';
                measuredValue['redMax'] = '';
                setMeasuredValue(measuredValue);
            } else if (index == 2) {
                measuredValue['yellowMax'] = event.target.value;
                measuredValue['redMin'] = event.target.value;
                measuredValue['redMinLabel'] = 'Less than ' + event.target.value;
                measuredValue['redMax'] = '';
                setMeasuredValue(measuredValue);
            } else {
                measuredValue['redMax'] = event.target.value;
                setMeasuredValue(measuredValue);
            }
        } else {
            if (index == 1) {
                measuredValue['green'] = event.target.value;
                measuredValue['yellowMin'] = event.target.value;
                measuredValue['yellowMinLabel'] = 'Greater than ' + event.target.value;
                measuredValue['yellowMax'] = '';
                measuredValue['redMin'] = '';
                measuredValue['redMax'] = '';
                setMeasuredValue(measuredValue);
            } else if (index == 2) {
                measuredValue['yellowMax'] = event.target.value;
                measuredValue['redMin'] = event.target.value;
                measuredValue['redMinLabel'] = 'Greater than ' + event.target.value;
                measuredValue['redMax'] = '';
                setMeasuredValue(measuredValue);
            } else {
                measuredValue['redMax'] = event.target.value;
                setMeasuredValue(measuredValue);
            }
        }
    }

    return (<React.Fragment>
        <Dialog
            open={open}
            maxWidth={maxWidth}
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            {!isSubmitionCompleted && !isMessageView && (
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Edit Aspects </DialogTitle>
                    <DialogContent>
                        <Formik
                            enableReinitialize
                            key={fakecount}
                            initialValues={initialValues}
                            onSubmit={(values, { setSubmitting }) => {
                                var unit;
                                console.log('values', values);
                                
                                var valueType, final;
                                values['greenCategory'] == 'Greater than Equal' ? valueType = 'dc' : valueType = 'in';
                                valueType == 'in' ? Number(measuredValue['yellowMax']) > Number(measuredValue['yellowMin']) ? final = true : final = false : Number(measuredValue['yellowMin']) > Number(measuredValue['yellowMax']) ? final = true : final = false
                                valueType == 'in' ? Number(measuredValue['redMax']) > Number(measuredValue['redMin']) ? final = true : final = false : Number(measuredValue['redMin']) > Number(measuredValue['redMax']) ? final = true : final = false
                                if (final || values['inspectionType'] == 'Visual') {
                                    if (values['parameter'] != 'Leakage') {
                                        console.log(values['parameter']);
                                        var units = unitList.find(x => x.parameter == values['parameter']);
                                        console.log("UNITS->", units);
                                        if (!units) {
                                            toast.warning("Unit of measurement not found...cannot add this aspect");
                                            return;
                                        } else unit = units['uom']['metric'];
                                    } else {
                                        unit = '-'
                                    }
                                    let green, yellow, red;
                                    if (values['inspectionType'] == 'Visual') {
                                        green = 'Ok'
                                        yellow = '';
                                        red = 'Not-ok';
                                    } else {
                                        green = measuredValue['green'];
                                        yellow = measuredValue['yellowMin'] + '-' + measuredValue['yellowMax'];
                                        red = measuredValue['redMin'] + '-' + measuredValue['redMax'];
                                    }
                                    var data = {
                                        aspect: values['aspect'], parameter: values['parameter'], inspectionType: values['inspectionType'], inspectionBasic: values['inspectionBasic'],
                                        type: values['type'], department: values['department'], green: green, yellow: yellow, red: red, unit: unit, greenCategory: values['greenCategory'],
                                        yellowMin: measuredValue['yellowMin'], yellowMax: measuredValue['yellowMax'], redMin: measuredValue['redMin'], redMax: measuredValue['redMax'], isActive: true
                                    };
                                    console.log('data', data);
                                    apiService2('POST', myConfig.updateAspectEP, data)
                                        .then(response => {
                                            console.log(response);
                                            toast.success("Aspect added successfully");
                                            setSubmitionCompleted(true);
                                            setMessageView(true);
                                            handleClose();
                                        })
                                        .catch(error => {
                                            console.error(error);
                                        })

                                    // setInspectionList([...data])
                                    // var datas = { greenCategory: '', green: '', yellowMin: '', yellowMax: '', redMin: '', redMax: '' }
                                    // setMeasuredValue(datas)
                                } else {
                                    toast.warning("Please enter correct values");
                                }
                            }
                            }
                            // Need to expand the validation schema more later
                            validationSchema={
                                Yup.object().shape({
                                    aspect: Yup.string().required("Required"),
                                    parameter: Yup.string().required("Required"),
                                    inspectionType: Yup.string().required("Required"),
                                    inspectionBasic: Yup.string().required("Required"),
                                    type: Yup.string().required("Required"),
                                    department: Yup.string().required("Required"),
                                    green: Yup.number(),
                                    yellowMax: Yup.number(),
                                    redMax: Yup.number(),
                                })}>
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    handleReset,
                                    resetForm,
                                    setFieldValue
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        {/*  */}
                                        <Grid container spacing={3}>
                                            {(() => {
                                                let result = [];
                                                let enumlist = [];
                                                let label;
                                                result.push(<Grid container spacing={3}></Grid>)
                                                for (const item of inspectionAspectsModel.filter(x => x.measuredValue == false)) {
                                                    label = item.label
                                                    if (item.hasOwnProperty("required")) { label = item.required ? label + "*" : label } else label = label;
                                                    if (item.select == true) {
                                                        if (item.field == 'department') {
                                                            enumlist = modDeptList;
                                                        } else if (item.field == 'parameter') {
                                                            enumlist = modUnitList;
                                                        } else {
                                                            enumlist = item.enum;
                                                        }
                                                        result.push(
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    label={label}
                                                                    name={item.field}
                                                                    value={values[item.field]}
                                                                    error={errors[item.field] && touched[item.field]}
                                                                    onBlur={handleBlur}
                                                                    disabled={values['inspectionType'] == 'Measured' && item.field == 'value'}
                                                                    onChange={handleChange}
                                                                    margin="normal"
                                                                    type="text"
                                                                    select
                                                                    defaultValue={''}
                                                                    sx={{ display: 'flex', width: "100%" }}>
                                                                    {enumlist.map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>)
                                                    } else {
                                                        result.push(
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    label={label}
                                                                    error={errors[item.field] && touched[item.field]}
                                                                    name={item.field}
                                                                    value={values[item.field]}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={errors[item.field] && "Incorrect entry."}
                                                                    margin="normal"
                                                                    type="text"
                                                                    sx={{ display: 'flex', width: "100%" }} />

                                                            </Grid>)
                                                    }
                                                }
                                                return result;
                                            })()}
                                            {
                                                values['inspectionType'] == 'Measured' && <Grid item xs={2}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Green Category'}
                                                        name={'greenCategory'}
                                                        value={values['greenCategory']}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        type="text"
                                                        select
                                                        defaultValue={''}
                                                        sx={{ display: 'flex', width: "100%" }}>
                                                        {enumList.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            }
                                            {
                                                values['inspectionType'] == 'Measured' &&
                                                <Grid item xs={2}><TextField
                                                    id="outlined-basic"
                                                    label={'Green'}
                                                    name='green'
                                                    value={measuredValue['green']}
                                                    onChange={(e) => {
                                                        setFieldValue('green', e.target.value)
                                                        handleValue(values, e, 1)
                                                    }}
                                                    onBlur={handleBlur}
                                                    helperText={errors.name && "Incorrect entry."}
                                                    margin="normal"
                                                    type="text"
                                                    sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>
                                            }
                                            {values['inspectionType'] == 'Measured' &&
                                                <Grid item xs={2}><TextField
                                                    id="outlined-basic"
                                                    label={'Yellow From'}
                                                    name={'yellowMin'}
                                                    value={measuredValue['yellowMinLabel']}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={errors.name && "Incorrect entry."}
                                                    margin="normal"
                                                    type="text"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>

                                            }
                                            {
                                                values['inspectionType'] == 'Measured' &&
                                                <Grid item xs={2}><TextField
                                                    id="outlined-basic"
                                                    label={'Yellow To'}
                                                    name={'yellowMax'}
                                                    value={measuredValue['yellowMax']}
                                                    onChange={(e) => {
                                                        setFieldValue('yellowMax', e.target.value)
                                                        handleValue(values, e, 2)
                                                    }}
                                                    onBlur={handleBlur}
                                                    helperText={errors.name && "Incorrect entry."}
                                                    margin="normal"
                                                    type="text"
                                                    sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>

                                            }
                                            {
                                                values['inspectionType'] == 'Measured' &&
                                                <Grid item xs={2}><TextField
                                                    id="outlined-basic"
                                                    label={'Red From'}
                                                    name={'redMin'}
                                                    value={measuredValue['redMinLabel']}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    helperText={errors.name && "Incorrect entry."}
                                                    margin="normal"
                                                    type="text"
                                                    sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>
                                            }
                                            {
                                                values['inspectionType'] == 'Measured' &&
                                                <Grid item xs={2}><TextField
                                                    id="outlined-basic"
                                                    label={'Red To'}
                                                    name={'redMax'}
                                                    value={measuredValue['redMax']}
                                                    onChange={(e) => {
                                                        setFieldValue('redMax', e.target.value)
                                                        handleValue(values, e, 3)
                                                    }}
                                                    onBlur={handleBlur}
                                                    helperText={errors.name && "Incorrect entry."}
                                                    margin="normal"
                                                    type="text"
                                                    sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>
                                            }
                                        </Grid>
                                        <DialogActions>
                                            <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                Close
                                            </Button>
                                            <Button type="reset" variant="contained" color="primary" onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}
                                                disabled={!dirty || isSubmitting}>
                                                Reset
                                            </Button>
                                            <Button type="submit" variant="contained" color="primary" >
                                                update
                                            </Button>
                                        </DialogActions>

                                    </form>
                                )
                            }}
                        </Formik>
                    </DialogContent>
                </React.Fragment>
            )}
        </Dialog>
    </React.Fragment>)
};
export default EditAspect;
import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { login, reset } from '../../features/auth/authSlice';
import Spinner from '../../components/Spinner';
import {
  TextField,
  Button,
  Typography,
  Grid,
  Link,
  Checkbox,
  Box,
  Paper,
  Container,
  FormControlLabel,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import * as Yup from "yup";
import backgroundimage from "../../images/arvam-login-2-base.png";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from 'formik';
import authService from '../../features/auth/authService';
import { apiService2, apiService3 } from '../../features/apiService';
import { myConfig } from '../../settings';

function Login() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const [isActive, setIsActive] = useState(false);
  const [expiryTime, setExpiryTime] = useState(0);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [timeLeft, setTimeLeft] = useState({
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = useCallback(() => {
    const difference = expiryTime - new Date().getTime();
    if (difference > 0) {
      return {
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return null; // Timer has expired
  }, [expiryTime]);

  useEffect(() => {
    if (isActive) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [calculateTimeLeft, isActive, timeLeft]);

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )
  if (isError) {
    toast.error(message);
    dispatch(reset())
  }
  if (isSuccess || user) {
    navigate('/')
  }
  if (isLoading) {
    return <Spinner />
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string().required('Password is required'),
  })

  function handleClose() { }

  function handleVerifyOtp() {
    authService.verifyOtp({ email: email, otp: otp })
    .then(response => {
      if(response.status == 200) {
        setOpen(false);
        setIsActive(false);
        setExpiryTime(0);
        dispatch(login(response.data));
      } else {
        toast.error(response.data.message);
      }
       
    }, error => {
      console.log("error->", error);
    })
  }

  function resentOtp() {
    apiService3('POST',  myConfig.resentOtpEp, { email: email })
      .then(response => {
        setExpiryTime(new Date().getTime() + 120000);
        setIsActive(true);
      })
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
        }}>
        <Grid container>
          <Grid
            item
            xs={false}
            sm={5}
            md={7}
            sx={{
              backgroundImage: 'url(' + backgroundimage + ')',
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.primary[100]
                  : t.palette.grey[600],
              backgroundSize: "96%",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h3">
                Sign in
              </Typography>

              <Formik
                key={"login"}
                initialValues={{
                  email: '',
                  password: ''
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  const userData = {
                    email: values["email"],
                    password: values["password"]
                  }
                  setEmail(values["email"]);
                  var response = await authService.login(userData);
                  if (response['type'] == 'login') {
                    dispatch(login(response));
                  } else {
                    setExpiryTime(new Date().getTime() + 120000);
                    setOpen(true);
                    setIsActive(true);
                  }
                  //
                }}
                // Need to expand the validation schema more later
                validationSchema={validationSchema} >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    resetForm
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        margin="normal"
                        error={errors['email'] && touched['email']}
                        onBlur={handleBlur}
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        onChange={handleChange}
                        autoComplete="email"
                        autoFocus
                      />
                      <FormHelperText style={{ color: 'red', fontSize: '14px' }}>{errors['email'] && touched['email'] ? errors['email'] : ""}</FormHelperText>
                      <TextField
                        margin="normal"
                        error={errors['password'] && touched['password']}
                        onBlur={handleBlur}
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        onChange={handleChange}
                        id="password"
                        autoComplete="current-password"
                      />
                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                      />
                      <Button type="submit" variant="contained" color="primary"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }} data-cy="submit">

                        Sign In
                      </Button>
                      <Grid container>
                        <Grid item xs>
                          <Link href="/forgot" variant="body2" color={colors.primary[100]}>
                            Forgot password?
                          </Link>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verifiy OTP</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="otp"
            label="OTP"
            name="otp"
            autoFocus
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              padding: '2px',
              color: '#007BFF',
            }}
          >
            {timeLeft ? (
              <>
                <p style={{ fontSize: '2.5em', margin: '10px 0' }}>
                  {timeLeft.minutes.toString().padStart(2, '0')}:
                  {timeLeft.seconds.toString().padStart(2, '0')}
                </p>
              </>
            ) : (
              <button style={{ background: 'none', border: 'none', color: '#007BFF', textDecoration: 'underline', cursor: 'pointer' }} onClick={resentOtp}>
                Resend OTP
              </button>
            )}
          </div>
          <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', }}>
            <Button type="button" variant="contained" color="primary" onClick={handleVerifyOtp}>
              Verifiy
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default Login
import { Box, FormControl, IconButton, MenuItem, Select, useTheme, Tooltip, Menu, Typography } from "@mui/material";
import Badge from '@mui/material/Badge';
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import SimpleDialog from "../../components/SimpleDialog";
import { myConfig, VERSION } from "../../settings.js";
import { Lock } from "@mui/icons-material";
import { logout, reset } from "../../features/auth/authSlice.js";
import NotificationDropdown from "../../components/Notification.jsx";
import { apiService2 } from "../../features/apiService.js";
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const [anchorElUser, setAnchorElUser] = useState(null);


  const [facility, setFacility] = useState('');
  const [facilitylist, setFacilityList] = useState(getFaciltyList);
  const [open, setOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  let content = [{ 'key': 'Version', "value": VERSION }];
  let pofileDropDown = [
    { 'id': '1', "value": 'Profile', "icon" : <PersonOutlinedIcon /> , to:'/profile' }, 
    { 'id': '2', "value": 'Change Password', "icon" : <Lock /> , to:'/change-password' },
    { 'id': '3', "value": 'Logout', "icon" : <LogoutIcon /> }
  ];
  let title = "About ARVAM EAM Software";

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/')
  }

  function getFaciltyList() {
    let facilitylist = JSON.parse(localStorage.getItem('facilitylist'));
    if (facilitylist && facilitylist.length > 0) {
      if (!localStorage.getItem('facility')) {
        setFacility(facilitylist[0].name);
        localStorage.setItem('facility', facilitylist[0]._id);
      } else {
        const fac = facilitylist.find((item) => item._id === localStorage.getItem('facility'));
        setFacility(fac.name);
      }
      return facilitylist.map((item) => item.name);
    } else {
      return [];
    }
  }


  function facilitys(e) {
    setFacility(e.target.value);
    const facilitylist = JSON.parse(localStorage.getItem('facilitylist'));
    const fac = facilitylist.find((item) => item.name === e.target.value);
    localStorage.setItem('facility', fac._id);
  }

  function handleAbout() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setProfileOpen(false);
  }
  const handleOpenUserMenu = (event) => {
    setProfileOpen(true);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
          <Select
            id="outlined-basic"
            name={'facility'}
            value={facility}
            onChange={facilitys}
            margin="normal"
            type="text"
            defaultValue={''}
            sx={{
              display: 'flex', width: "150px",
              padding: "0px 0px 0px 0px",
              menuPaper: {
                maxHeight: 100
              }
            }}>
            {facilitylist.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Tooltip id="tt-about" title="About">
          <IconButton onClick={handleAbout}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        {/* <IconButton>
          <Badge badgeContent={4} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
        <NotificationDropdown />
        <Tooltip id="tt-logout" title="Color Mode">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip id="tt-logout" title="Logout">
          <IconButton onClick={onLogout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleOpenUserMenu}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
      <SimpleDialog
        title={title}
        content={content}
        right={110}
        type="version"
        open={open}
        onClose={handleClose}
      />
      <SimpleDialog
        title={title}
        type="profile"
        right={20}
        content={pofileDropDown}
        open={profileOpen}
        onClose={handleClose}
      />
    </Box>
  );
};

export default Topbar;


import React, { useState, useEffect } from "react";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useSelector } from 'react-redux';
import { Formik } from "formik";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import { toast } from "react-toastify";
import {
    TextField,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from "yup";
import { failurePreventionModel } from "../../schema/schema";
import { apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
let fakecount = 0;
const FailurePreventionUpdate = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const [woId, setWoId] = useState('s');
    const [assetId, setAssetId] = useState('d');
    const [bdTime, setBDTime] = useState('d');
    const [bdCode, setBDCode] = useState('d');
    const [state, setState] = useState('d');
    const [casuse, setCasuse] = useState();
    const [action, setAction] = useState();
    const [mainC, setMain] = useState();
    const [inspC, setInspec] = useState();
    const [maxWidth, setMaxWith] = useState('lg');
    const [employeeData, setEmployeeData] = useState([])


    let totalrows = 3;

    function getInitialValues() {
        const failurePrevention = props.open.failurePrevention;
        if (failurePrevention['workorderId']) {
            setWoId(failurePrevention['workorderId']['wonumber'])
        }
        if (failurePrevention['assetId'])
            setAssetId(failurePrevention['assetId']['assetid'])
        if (failurePrevention)
            setBDTime(failurePrevention['duration'])
        if (failurePrevention)
            setBDCode(failurePrevention['code'])
        if (failurePrevention)
            setState(failurePrevention['state'])
        var list = failurePrevention['failureDetails']
        if (list && list.length > 0) {
            setEmployeeData([...list])
        } else {
            let data = []
            setEmployeeData([...data])
        }
    }


    if (props.open.state & !open) {
        getInitialValues();
        setMaxWith('lg')
        //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
    }

    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }
    if (props.open.state & !open) {
        setOpen(true);
        //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
    }

    function handleRetry() {
        setSubmitionError(false);
        setSubmitionCompleted(false);
    }


    function handleAddRow() {
        if (!casuse || !action || !mainC || !inspC) {
            toast.error("Please fill all the fields");
        } else {
            employeeData.push({ preventionCase: casuse, actionTaken: action, maintaenanceChage: mainC, inspectionChange: inspC })
            setEmployeeData([...employeeData])
            setCasuse('')
            setAction('')
            setMain('')
            setInspec('')
        }
    }
    function handleDeleteRow(id) {
        employeeData.splice(id, 1)
        setEmployeeData([...employeeData]);
    }


    function handleCasue(event) {
        setCasuse(event.target.value);
    }
    function handleAction(event) {
        setAction(event.target.value);
    }
    function handleMChange(event) {
        setMain(event.target.value);
    }
    function handleIChange(event) {
        setInspec(event.target.value);
    }

    function handleReset() {
        setCasuse('')
        setAction('')
        setMain('')
        setInspec('')
    }

    function save() {
        const failurePrevention = props.open.failurePrevention;
        var values = {
            failureDetails: employeeData,
            state: 'Created',
            _id: failurePrevention['_id']
        }
        apiService2('POST', myConfig.addFailurePreventionEP, values)
            .then(resp => {
                setSubmitionCompleted(true);
                setMaxWith('sm')
            })
            .catch(error => {
                console.log(["Add WR - Errored->", error]);
                setMaxWith('sm')
                setSubmitionError(true);
                setSubmitionCompleted(true);
            });
    }

    function handleSubmit() {
        const failurePrevention = props.open.failurePrevention;
        var value = {
            failureDetails: employeeData,
            state: 'Submitted',
            _id: failurePrevention['_id']
        }
        apiService2('POST', myConfig.addFailurePreventionEP, value)
            .then(resp => {
                console.log(resp);
                setMaxWith('sm')
                setSubmitionCompleted(true);
            })
            .catch(error => {
                console.log(["Add WR - Errored->", error]);
                setMaxWith('sm')
                setSubmitionError(true);
                setSubmitionCompleted(true);
            });
    }

    function handleComplete() {
        const failurePrevention = props.open.failurePrevention;
        var value = {
            failureDetails: employeeData,
            state: 'Completed',
            _id: failurePrevention['_id']
        }
        apiService2('POST', myConfig.addFailurePreventionEP, value)
            .then(resp => {
                console.log(resp);
                setMaxWith('sm')
                setSubmitionCompleted(true);
            })
            .catch(error => {
                console.log(["Add WR - Errored->", error]);
                setMaxWith('sm')
                setSubmitionError(true);
                setSubmitionCompleted(true);
            });
    }

    const valueDB = {};
    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={maxWidth}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Add Failure Prevention </DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                //enableReinitialize 
                                key={fakecount}
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log('mmmmmmm');

                                }}
                                // Need to expand the validation schema more later
                                validationSchema={Yup.object().shape({})}>
                                {props => {
                                    const {
                                        dirty,
                                        isSubmitting,
                                        resetForm
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label='BD WO ID'
                                                        name='woid'
                                                        value={woId}
                                                        margin="normal"
                                                        style={{ width: "100%" }}
                                                        type="text"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label='Asset ID'
                                                        name='assetId'
                                                        value={assetId}
                                                        margin="normal"
                                                        type="text"
                                                        style={{ width: "100%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label='BD Time'
                                                        name='boTime'
                                                        margin="normal"
                                                        type="text"
                                                        style={{ width: "100%" }}
                                                        value={bdTime}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label='BD Code'
                                                        name=''
                                                        margin="normal"
                                                        type="text"
                                                        value={bdCode}
                                                        style={{ width: "100%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id={`outlined-basic1`}
                                                        label='Failure Cause'
                                                        name={`casue`}
                                                        value={casuse}
                                                        margin="normal"
                                                        type="text"
                                                        multiline
                                                        style={{ width: "100%" }}
                                                        fullWidth
                                                        onChange={(event) => handleCasue(event)}
                                                        rows={totalrows}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id={`outlined-basic2`}
                                                        label='Action Taken'
                                                        name={`action`}
                                                        margin="normal"
                                                        type="text"
                                                        style={{ width: "100%" }}
                                                        value={action}
                                                        multiline
                                                        onChange={(event) => handleAction(event)}
                                                        rows={totalrows}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id={`outlined-basic3`}
                                                        label='Maintenance Change'
                                                        name={`mchanges`}
                                                        margin="normal"
                                                        type="text"
                                                        style={{ width: "100%" }}
                                                        value={mainC}
                                                        multiline
                                                        onChange={(event) => handleMChange(event)}
                                                        rows={totalrows}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id={`outlined-basic4`}
                                                        label='Inspection Changes'
                                                        name={`ichanges`}
                                                        margin="normal"
                                                        type="text"
                                                        style={{ width: "100%" }}
                                                        value={inspC}
                                                        onChange={(event) => handleIChange(event)}
                                                        multiline
                                                        rows={totalrows}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleAddRow}>
                                                    Add
                                                </Button>
                                                {
                                                    state == 'Created' && <Button type="button" variant="contained" color="primary" onClick={handleReset}>
                                                        Reset
                                                    </Button>
                                                }
                                            </DialogActions>
                                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                <TableContainer sx={{ maxHeight: 350 }}>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow style={{ textAlign: "left" }}>
                                                                <TableCell>Fault Prevention Cause</TableCell>
                                                                <TableCell>Action Taken</TableCell>
                                                                <TableCell>Maintenance Change</TableCell>
                                                                <TableCell>Inspection Changes</TableCell>
                                                                <TableCell>Action</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {employeeData.map(({ preventionCase, actionTaken, maintaenanceChage, inspectionChange }, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{preventionCase}</TableCell>
                                                                    <TableCell>{actionTaken}</TableCell>
                                                                    <TableCell>{maintaenanceChage}</TableCell>
                                                                    <TableCell>{inspectionChange}</TableCell>
                                                                    <TableCell>
                                                                        <IconButton onClick={() => handleDeleteRow(index)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>

                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
  
                                                {
                                                    state == 'Created' && <Button type="button" variant="contained" color="primary" disabled={isSubmitting} onClick={save}>
                                                        Save
                                                    </Button>
                                                }
                                                {
                                                    state == 'Created' && <Button type="button" variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                                                        Submit
                                                    </Button>
                                                }
                                                {
                                                    state == 'Submitted' && <Button type="button" variant="contained" color="primary" onClick={handleComplete} disabled={isSubmitting}>
                                                        Completed
                                                    </Button>
                                                }

                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>

                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Thanks! Failure Prevention updated</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Thanks</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment
                    >
                        <DialogTitle id="form-dialog-title">Sorry! Failure Prevention Update Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Try Again Later</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                                    Back
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}

            </Dialog>
        </React.Fragment>
    );
}

export default FailurePreventionUpdate;
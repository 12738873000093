/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import {
  ThemeProvider
} from "@mui/material/styles";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TableViewIcon from '@mui/icons-material/TableView';
import React, { useState, useEffect } from "react";
import { myConfig } from "../../settings.js";
import AddMaintenance from "./addmaintenance";
import EditMaintenance from "./editmaintenance";
import ViewMaintenance from "./viewmaintenance";
import { maintenanceModel } from "../../schema/schema.js";
import { useSelector } from 'react-redux';
import { apiService2 } from '../../features/apiService.js';
import { toast } from 'react-toastify';
import { getAccess } from "../../features/helpers/authhelper";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";
import dayjs from "dayjs";
import { Filter } from "../../features/helpers/filter.js";

const Maintenance = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [];
  const [open, setOpen] = useState(false);
  const [eopen, setEditOpen] = useState(false);
  const [vopen, setViewOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [selectedRow, setRow] = useState(-1);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedMaintenance, setMaintenance] = useState({});
  const { user } = useSelector((state) => state.auth);
  const access = getAccess(user, 'maintenance');
  const [trigger, setTrigger] = useState(false);

  // React Table Hooks
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  // Date Range Hooks
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, 'month'))
  const [toDate, setToDate] = useState(dayjs());
  function handleDelete() {
    console.log("Proceed with delete")
  }

  for (const item of maintenanceModel) {
    if (item.hasOwnProperty("isView")) {
      if (!item.isView) continue;
    }
    columns.push({ accessorKey: item['field'], header: item['label'], size: 150 })
  }

  useEffect(() => {
    handleFilter();
  }, [pagination, pagination.pageIndex, pagination.pageSize, sorting, refresh, columnFilters, globalFilter]);

  function handleFilter() {
    if (maintenanceList.length > 0) {
      setIsRefetching(true);
    }
    var data = {
      limit: pagination.pageSize,
      page: pagination.pageSize * pagination.pageIndex,
      sorting: sorting,
      filter: columnFilters,
      fromDate: fromDate,
      toDate: toDate,
      globalFilter: globalFilter,
    }
    apiService2('POST', myConfig.getMaintenanceFilterEP, data)
      .then(response => {
        var maintenanceList = response.data?.maintenance;
        setRowCount(response.data?.count);
        maintenanceList.forEach((res, index) => {
          res.createddate = timeZoneConverter(res.createddate)
          res.safetyclearance = res.safetyclearance ? "Yes" : "No";
          res.approvalrequired = res.approvalrequired ? "Yes" : "No";
        });
        setMaintenanceList(maintenanceList);
        setIsRefetching(false);
      })
      .catch(error => {
        console.error(error);
      })
  }

  function handleResetFilter() {
    setFromDate(dayjs().subtract(1, 'month'));
    setToDate(dayjs());
    setGlobalFilter('');
    setColumnFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
  }

  function handleView() {
    if (selectedRow != -1) {
      setViewOpen(true);
    } else {
      toast.error("Select a row first...");
    }
  }

  function handleClose() {
    //console.log("Setting open to false");
    //console.log(["show=>", show]);
    setOpen(false);
    setEditOpen(false);
    setViewOpen(false);
    setOpenDelete(false);
    setRefresh(!refresh);
  }

  function handleClickOpen() {
    //console.log("Setting open to true");
    setOpen(true);
  }

  function handleClickEdit() {
    setTrigger(!trigger);
    if (selectedRow != -1) {
      if (selectedMaintenance.status != "CLOSED") {
        setEditOpen(true);
      } else {
        toast.error("Maintenance is closed, cannot edit...");
      }
    } else {
      toast.error("Select a row first...");
    }
  }
  useEffect(() => {
    const maint = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const item of maintenanceList) {
        if (item._id == maint) {
          setMaintenance(item);
          setRow(index);
          break;
        }
        ++index;
      }
    } else {
      setMaintenance({});
    }
  }, [rowSelection, trigger]);


  function handleClickDelete() {
    if (selectedRow != -1) {
      setOpenDelete(true);
    } else {
      toast.error("Select a row first...");
    }
  }

  function handleDelete() {
    let details = {
      _id: selectedMaintenance._id
    }
    apiService2('POST', myConfig.deleteMaintenanceEP, details)
      .then(response => {
        handleClose()
      })
      .catch(error => {
        console.error(error);
      })
  }



  function CustomToolbar() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <Box className="mb-1">

        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
            Add
          </Button>
        }
        {
          access?.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleClickEdit}>
            Edit
          </Button>
        }
        <Button variant="contained" color="primary" startIcon={<TableViewIcon />} onClick={handleView}>
          View
        </Button>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box m="20px" data-cy="Maintenance">
        <Header title="Maintenance" subtitle="" />
        <Box
          m="40px 0 0 0"
          height="70vh"
        >
          {CustomToolbar()}
          <Filter params={{fromDate, setFromDate, toDate, setToDate, handleFilter, handleResetFilter}} />
          {tableView(true, columns, maintenanceList, setRowSelection, rowSelection, '_id', false, null, null, false, isRefetching, pagination, setPagination, sorting, setSorting, rowCount, columnFilters, setColumnFilters, globalFilter, setGlobalFilter)}
          <AddMaintenance open={{ state: open, setfn: handleClose }} />
          <EditMaintenance open={{ state: eopen, setfn: handleClose, maintenance: selectedMaintenance }} />
          <ViewMaintenance open={{ state: vopen, setfn: handleClose, maintenance: selectedMaintenance }} />
          <Dialog
            open={openDelete}
            fullWidth={true}
            maxWidth={'xs'}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <React.Fragment>
              <DialogTitle id="form-dialog-title">Delete Workorder</DialogTitle>
              <DialogContent>
                <DialogContentText variant="h6">Are you sure you want to Delete?</DialogContentText>
                <DialogActions sx={{
                 
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                  <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                    Confirm
                  </Button>
                  <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </DialogContent>
            </React.Fragment>
          </Dialog>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Maintenance;

import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, Radio, RadioGroup, useTheme } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { tokens } from "../../theme";

export const Filter = (props) => {
    // Props passed 
    const fromDate = props.params.fromDate;
    const setFromDate = props.params.setFromDate;
    const toDate = props.params.toDate;
    const setToDate = props.params.setToDate;
    const filterMethod = props.params.handleFilter;
    const resetFilter = props.params.handleResetFilter;
    const isFilterType = props.params.isFilterType;
    const setFilterType = props.params.setFilterType;
    const filterType = props.params.filterType;

    const today = new Date();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header">
                Filter
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    {isFilterType && <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Filter Type</FormLabel>
                            <RadioGroup value={filterType} onChange={(e) => setFilterType(e.target.value)}
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female" sx={{ display: 'flex', flexDirection: 'row' }}
                                name="radio-buttons-group">
                                <FormControlLabel value="created" control={<Radio />} label="Created Date" />
                                <FormControlLabel value="start" control={<Radio />} label="Start Date" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel id="simple-label" sx={{ fontSize: '16px', marginBottom: '5px' }}>From Date</InputLabel>
                        <DatePicker
                            id={'date'}
                            name={'fromdate'}
                            value={dayjs(fromDate)}
                            onChange={(date) => setFromDate(date)}
                            sx={{ display: 'flex', flexdirection: 'row', width: "100%" }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel id="simple-label" sx={{ fontSize: '16px', marginBottom: '5px' }}>To Date</InputLabel>
                        <DatePicker
                            id={'toDate'}
                            name={'toDate'}
                            value={dayjs(toDate)}
                            minDate={fromDate}
                            onChange={(date) => setToDate(date)}
                            sx={{ display: 'flex', flexdirection: 'row', width: "100%" }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1}>
                        <InputLabel sx={{ fontSize: '16px', marginBottom: '34px' }} />
                        <Button onClick={filterMethod} sx={{ display: 'flex', flexdirection: 'row', width: "100%" }} >Filter</Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1}>
                        <InputLabel sx={{ fontSize: '16px', marginBottom: '34px' }} />
                        <Button onClick={resetFilter} sx={{ display: 'flex', flexdirection: 'row', width: "100%" }}>Reset</Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>)
}

import { tokens } from "../../theme";
import { Box, Typography, useTheme, Button, Tab, TabContext, TabList, Tabs, } from "@mui/material";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { getAccess } from "../../features/helpers/authhelper";
import ProcurementOrder from "../procurementorder";
import ProcurementRequest from "../procurementrequest";

const subTitleList = ['Procurement Request', 'Procurement Order']



const Procurement = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = useState('1');
    const [subTitle, setSubTitle] = useState('Procurement Request');
    const { user } = useSelector((state) => state.auth);
    const access = getAccess(user, 'procurement');
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSubTitle(subTitleList[newValue - 1])
    };

    return (
        <Box m="10px 20px 20px 20px" data-cy="Procurement">
            <Header title="Procurement" subtitle={subTitle} />
            <Box
                m="10px 0 0 0"
                height="70vh">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} textColor="primary"
                        indicatorColor="primary">
                        <Tab label="Procurement Request" value="1" />
                        <Tab label="Procurement Order" value="2" />
                    </Tabs>
                </Box>
                {value == 1 && <ProcurementRequest />}
                {value == 2 && <ProcurementOrder />}

            </Box>
        </Box>
    );
}

export default Procurement;
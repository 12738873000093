import { Box, Typography, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify'
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { procurementRequestModel } from "../../schema/schema";
import { getAccess } from "../../features/helpers/authhelper";
import AddProcurementRequest from "./add";
import EditProcurementRequest from "./edit";
import ProcurementAdd from "./procurementadd";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";

const ProcurementRequest = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [];

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openPO, setOpenPO] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [procurementRequestList, setProcurementRequestList] = useState([]);
    const [procurementRequest, setProcurementRequest] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [selectedrow, setRow] = useState(-1);
    const [rowSelection, setRowSelection] = useState({});
    const [message, setMessage] = useState('');
    const access = getAccess(user, 'procurement');


    for (const item of procurementRequestModel) {
        if (item.hasOwnProperty("skip")) {
            if (item.skip) continue;
        }
        columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
    }

    useEffect(() => {
        apiService2('GET', myConfig.getProcurementRequestEP)
            .then(response => {
                var procurementRequestList = response.data;
                procurementRequestList?.forEach(res => {
                    res.prmdate = timeZoneConverter(res.prmdate)
                    res.isActive = res.isActive ? "Active" : "Inactive"
                    res.cdate = timeZoneConverter(res.createdOn)
                    res.approvedDate = res.approvedDate != null ? timeZoneConverter(res.approvedDate) : ''
                })
                setProcurementRequestList(procurementRequestList);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    useEffect(() => {
        const wrnumber = Object.keys(rowSelection)[0];
        const selected = Object.values(rowSelection)[0];
        let index = 0;
        if (selected) {
            for (const temp of procurementRequestList) {
                if (temp._id == wrnumber) {
                    setProcurementRequest(temp);
                    setRow(index);
                    break;
                }
                ++index;
            }
        } else {
            setProcurementRequest({});
        }
    }, [rowSelection]);

    function handleClose() {
        setOpen(false);
        setOpenEdit(false)
        setOpenDelete(false)
        setRefresh(!refresh);
        setOpenApprove(false);
        setOpenPO(false);
        setRowSelection({});
        setProcurementRequest({});
    }

    function handleClickOpen() {
        setOpen(true);
        setRefresh(false);
    }

    function handleClickEdit() {
        if (selectedrow >= 0) {
            if(procurementRequest?.status == 'Approved'){
                toast.warning("Procurement Request already Approved");
                return;
            }
            setOpenEdit(true);
            setRefresh(false);
        } else toast.warning("Select a any one Procurement Request first ");
    }

    function handleClickDelete() {
        if (selectedrow >= 0) {
            if (procurementRequest?.status == 'Approved') {
                toast.warning("Procurement Request already Approved");
                return;
            }
            setMessage("Delete");
            setOpenDelete(true);
        } else toast.warning("Select a any one Procurement Request first ");
    }

    function handleAcceptPR() {
        if (selectedrow >= 0) {
            if (procurementRequest?.status == 'Approved') {
                toast.warning("Procurement Request already Approved");
                return;
            }
            setOpenApprove(true);
            setRefresh(false);
        } else toast.warning("Select a any one Procurement Request first ");
    }

    function handleClickPO() {
        if (selectedrow >= 0) {
            if (procurementRequest?.status != 'Approved') {
                toast.warning("ProcurementRequest not Approved");
                return;
            } else if (procurementRequest?.isProcurementpo) {
                toast.warning("Procurement Request already PO Created");
                return;
            }
            setOpenPO(true);
            setRefresh(false);
        } else toast.warning("Select a any one Procurement Request first ");
    }

    function handleDelete() {
        apiService2('POST', myConfig.deleteProcurementRequestEP, { "_id": procurementRequest._id })
            .then(resp => {
                handleClose();
            })
            .catch(error => {
                console.log(["Procurement Request Add Failed->", error]);
                handleClose();
            });
    }

    function handleApprove() {
        apiService2('POST', myConfig.approveProcurementRequestEP, { "_id": procurementRequest._id })
            .then(resp => {
                handleClose();
                setRefresh(!refresh);
            })
            .catch(error => {
                console.log(["Procurement Request Add Failed->", error]);
                handleClose();
            });
    }


    function CustomToolbar() {
        return (
            <Box className="mb-1">
                {
                    access.isCreate && <Button variant="contained" color="primary"startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Add
                    </Button>
                }
                {
                    access.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleClickEdit}>
                        Edit
                    </Button>
                }
                {
                    access.isDeleted && <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={handleClickDelete}>
                        Delete
                    </Button>
                }
                {
                    access.isCreate && <Button variant="contained" color="primary" onClick={handleAcceptPR}>
                        Approve
                    </Button>
                }
                {
                    access.isCreate && <Button variant="contained" color="primary" onClick={handleClickPO}>
                        Create PO
                    </Button>
                }
            </Box>
        );
    }
    return (
        <Box m="20px">
            {/* <Header title="ProcurementRequest" subtitle="" /> */}
            <Box
                m="20px 0 0 0"
                height="70vh">
                {CustomToolbar()}
                {tableView(false, columns, procurementRequestList, setRowSelection, rowSelection, '_id')}
                <AddProcurementRequest open={{ state: open, setfn: handleClose }} />
                <EditProcurementRequest open={{ state: openEdit, setfn: handleClose, procurementRequest: procurementRequest }} />
                <ProcurementAdd open={{ state: openPO, setfn: handleClose, procurementRequest: procurementRequest }} />
                <Dialog
                    open={openDelete}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">{message} Procurement Request</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to {message}?</DialogContentText>
                            <DialogActions sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
                <Dialog
                    open={openApprove}
                    fullWidth={true}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Approve Procurement Request</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Are you sure you want to Approve the status?</DialogContentText>
                            <DialogActions sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button type="button" variant="contained" color="secondary" onClick={handleApprove}>
                                    Confirm
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Box>
    );
};

export default ProcurementRequest;

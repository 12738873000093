/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import { Box, useTheme, Button, Tabs, Tab } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TableViewIcon from '@mui/icons-material/TableView';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { useState, useEffect, useMemo } from "react";
import { toast } from 'react-toastify';
import { myConfig } from "../../settings.js";
import { workRequestModel } from "../../schema/schema.js";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService.js';
import { MaterialReactTable } from 'material-react-table';
import AddWR from "./addworkrequest";
import UpdateWR from "./updateworkrequest";
import AcceptWR from "./acceptworkrequest";
import ViewWR from "./viewworkrequest";
import { getAccess } from "../../features/helpers/authhelper";
import { tableView } from "../../features/helpers/table.js";
import dayjs from "dayjs";
import { Filter } from "../../features/helpers/filter.js";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";

const WorkRequest = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [];
  const [open, setAddWROpen] = useState(false);
  const [uopen, setUpdateOpen] = useState(false);
  const [aopen, setAssignOpen] = useState(false);
  const [vopen, setViewOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const access = getAccess(user, 'workrequest');

  //const [addopen, setAddWROpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [workRequestList, setWorkRequestList] = useState([]);
  const [selectedrow, setRow] = useState(-1);
  const [selectedWorkRequest, setWR] = useState({});
  const [assignedWRList, setAssignedWRs] = useState([]);
  const [deptWRList, setDeptWRs] = useState([]);
  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  // React Table Hooks
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  // Date Range Hooks
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, 'month'))
  const [toDate, setToDate] = useState(dayjs());

  let wrlist = {};
  let arrayFieldList = [];
  let dateFieldList = []; 

  

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  //Build the required columns for the datagrid
  for (const item of workRequestModel) {
    if (item.hasOwnProperty("skip")) {
      //if(item.skip) continue;
    }
    if (item.hasOwnProperty("view")) {
      if (!item.view) continue;
    }
    if (item.type == Boolean) { columns.push({ accessorKey: item['field'], header: item['label'], size: 150, accessorFn: d => { return d.approvalrequired ? 'yes' : 'no' } }); }
    else { columns.push({ accessorKey: item['field'], header: item['label'], size: 150 }); }
  }

  useEffect(() => {
    identifyArrayList();
    identifyDateFieldList();
  },[]);

  //Get all the workrequests
  useEffect(() => {
    handleFilter();

  }, [pagination, pagination.pageIndex, pagination.pageSize, sorting, refresh, columnFilters, globalFilter]);

  function handleFilter() {
    let data = {
      limit: pagination.pageSize,
      page: pagination.pageSize * pagination.pageIndex,
      sorting: sorting,
      filter: columnFilters,
      fromDate: fromDate,
      toDate: toDate,
      globalFilter: globalFilter,
    }
    apiService2('POST', myConfig.getWorkRequestFilterEP, data)
      .then(response => {
        console.log('handleFilter->Get WRs -> ', response.data);
        setRowCount(response.data?.count);
        wrlist = updateWorkRequest(response.data?.workRequest);
        setWorkRequestList(wrlist);
        getAssignedList(wrlist);
        getDeptList(wrlist);
      })
      .catch(error => {
        console.error(error);
      })
  }

  function handleResetFilter() {
    setFromDate(dayjs().subtract(1, 'month'));
    setToDate(dayjs());
    setGlobalFilter('');
    setColumnFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
  }

  function identifyArrayList() {
    for (const item of workRequestModel) {
      if (item['type'] === Array) arrayFieldList.push(item['field']);
    }
  }


  // Identify all the fields of type 'Date' to later apply timezoneconverter for display purposes
  function identifyDateFieldList() {
    for (const item of workRequestModel) {
      if (item['type'] === Date) dateFieldList.push(item['field']);
    }
  }

  function updateWorkRequest(wrlist) {
    for (const wr of wrlist) {
      for (const arrayitem of arrayFieldList) {
        if (wr.hasOwnProperty(arrayitem)) {
          const tmp = wr[arrayitem].join(" ");
          wr[arrayitem] = tmp;
          console.log("updateWR=>", arrayitem, tmp);
        }
      }
      for (const dateitem of dateFieldList) {
        if (wr.hasOwnProperty(dateitem)) {
          const tmp = timeZoneConverter(wr[dateitem]);
          wr[dateitem] = tmp;
        }
      }
    }
    return wrlist;
  }

  function getAssignedList(wrlist) {
    let assignedlist = [];
    for (const item of wrlist) {
      if (item.assignee === user.email || item.assigneddept === user.dept) {
        assignedlist.push(item);
      }
    }
    setAssignedWRs(assignedlist);
    return;
  }

  function getDeptList(wrlist) {
    let acceptedList = [];
    for (const item of wrlist) {
      if (item.assigneddept === user.dept && item.state === "Accepted") {
        acceptedList.push(item);
      }
    }
    setDeptWRs(acceptedList);
    return;
  }
                                            
  function handleClose() {
    console.log("WR Main - Handle Close Called");
    setAddWROpen(false);
    setAssignOpen(false);
    setUpdateOpen(false);
    setViewOpen(false);
    setRefresh(!refresh);
    setRowSelection({});                                                                                                                                                                                  
  }

  useEffect(() => {
    console.log(Object.keys(rowSelection));
    const wrnumber = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const tempwr of workRequestList) {
        if (tempwr.wrnumber == wrnumber) {
          setWR(tempwr);
          setRow(index);
          break;
        }
        ++index;
      }
    } else {

      setWR({});
    }
    //for (const item of rowSelection) {
    // console.log("->", item);
    //}
  }, [rowSelection]);

  function doChecks() {
    console.log("Selected Row->", selectedWorkRequest.state, user.email, selectedWorkRequest.createdby, user.dept);
    if (selectedrow != -1) {
      switch (selectedWorkRequest.state) {
        case "Created": {
          if (selectedWorkRequest.createdby !== user.email) {
            toast.warning("You are not the owner, cannot edit");
            return false;
          }
          break;
        }
        case "Assigned": {
          if (selectedWorkRequest.assigneddept !== user.dept) {
            toast.warning("You (" + user.dept + ") are not in the assigned dept -" + selectedWorkRequest.assigneddept + ", cannot edit");
            return false;
          }
          break;
        }
        case "Completed": {
          toast.warning("Work Request in completed state, cannot edit");
          return false;
        }
        case "Rejected": {
          toast.warning("Work Request in rejected state, cannot edit");
          return false;
        }
        case "Suspended": {
          toast.warning("Work Request in suspended state, cannot edit");
          return false;
        }
        case "Accepted": {
          toast.warning("Work Request in accepted state, cannot edit");
          return false;
        }

      }
    } else {
      toast.warning("Select a row/WR first ");
      return false;
    }
    return true
  }

  function handleAddWR() {
    setAddWROpen(true);
  }

  function handleAcceptWR() {
    if (selectedrow != -1) {
      if (selectedWorkRequest.state === "Accepted" || selectedWorkRequest.state === "Suspended" || selectedWorkRequest.state === "Completed" || selectedWorkRequest.state === "Rejected") {
        toast.warning("WorkRequest already in " + selectedWorkRequest.state + " State");
        return;
      }
      if (user.dept === selectedWorkRequest.assigneddept && selectedWorkRequest.state !== "Completed") {
        setAssignOpen(true);
      } else {
        console.log("User- dept->", user);
        toast.warning("Users from different Dept cannot do the assignment, WR is assigned to " + selectedWorkRequest.assigneddept);
      }
    } else toast.warning("Select a row/WR first ");
    return;
  }

  function handleUpdateWR() {
    console.log("Selected Row->", selectedWorkRequest.state, user.email, selectedWorkRequest.createdby, user.dept);
    if (doChecks()) {
      setUpdateOpen(true);
    }
  }

  function handleViewWR() {
    console.log("Set View Open");
    setViewOpen(true);
  }

  function CustomToolbar() {
    return (
      <Box className="mb-1">
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddWR}>
            Create
          </Button>
        }
        {
          access?.isView && <Button variant="contained" color="primary" startIcon={<TableViewIcon />} onClick={handleViewWR}>
            View
          </Button>
        }
        {
          access?.isDeleted && <Button variant="contained" color="primary" startIcon={<HandshakeIcon />} onClick={handleAcceptWR}>
            Accept/Reject
          </Button>
        }
      </Box>
    );
  }

  return (
    <Box m="20px" data-cy="Workrequest">
      <Header title="Work Request" subtitle="" />
      <Box
        m="40px 0 0 0"
        height="70vh"
      >
        {CustomToolbar()}
        <Filter params={{ fromDate, setFromDate, toDate, setToDate, handleFilter, handleResetFilter }} />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label='All WRs' />
            <Tab label='Your Dept WRs' />
            <Tab label='Accepted' />
          </Tabs>
        </Box>
        {/* TAB 1 Contents */}
        {currentTabIndex === 0 && (
          tableView(false, columns, workRequestList, setRowSelection, rowSelection, 'wrnumber', false, null, null, false, isRefetching, pagination, setPagination, sorting, setSorting, rowCount, columnFilters, setColumnFilters, globalFilter, setGlobalFilter)
        )}
        {currentTabIndex === 1 && (
          tableView(false, columns, assignedWRList, setRowSelection, rowSelection, 'wrnumber', false, null, null, false, isRefetching, pagination, setPagination, sorting, setSorting, rowCount, columnFilters, setColumnFilters, globalFilter, setGlobalFilter)
        )}
        {currentTabIndex === 2 && (
          tableView(false, columns, deptWRList, setRowSelection, rowSelection, 'wrnumber', false, null, null, false, isRefetching, pagination, setPagination, sorting, setSorting, rowCount, columnFilters, setColumnFilters, globalFilter, setGlobalFilter)
        )}
        <AddWR open={{ state: open, setfn: handleClose, workrequest: selectedWorkRequest }} />
        <UpdateWR open={{ state: uopen, setfn: handleClose, workrequest: selectedWorkRequest }} />
        <AcceptWR open={{ state: aopen, setfn: handleClose, workrequest: selectedWorkRequest }} />
        <ViewWR open={{ state: vopen, setfn: handleClose, workrequest: selectedWorkRequest }} />
      </Box>
    </Box>
  );
};

export default WorkRequest;
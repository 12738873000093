import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { login, reset } from '../../features/auth/authSlice';
import Spinner from '../../components/Spinner';
import {
    TextField,
    Button,
    Typography,
    Grid,
    Link,
    Checkbox,
    Box,
    Paper,
    Container,
    FormControlLabel
} from "@mui/material";
import backgroundimage from "../../images/arvam-login-2-base.png";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { apiService2, apiService3 } from '../../features/apiService';
import { myConfig } from '../../settings';


function ForgotPassword() {
    const [formData, setFormData] = useState({
        email: '',
    })
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (e) => {
        setIsLoading(true)
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        const email = data.get('email');
        console.log("Forgot Password: email ->", email);
        apiService3('POST',myConfig.fogotPasswordEP, { email: email })
            .then((response) => {
                setIsLoading(false)
                console.log("Forgot Password: response ->", response);
                if (response.data.message === 'Email has been sent to your email address') {
                    toast.success("Mail Sent Successfully");
                    navigate('/login')
                } else {
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log("Forgot Password: error ->", error);
                toast.error("Invalid Email");
            })
    }

    if (isLoading) {
        return <Spinner />
    }

    return (
        <Container component="main">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                    width: "100%",
                }}>
                <Box
                    sx={{
                        width: '400px',
                        padding: '30px',
                        borderRadius: '10px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                        textAlign: 'center',
                    }}
                    component={Paper}
                >
                    <Typography component="h1" variant="h3">
                        Forgot Password
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={onSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Button type="submit" variant="contained" color="primary"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}>
                            Send Mail
                        </Button>
                    </Box>
                </Box>

            </Box>
        </Container>
    )
}

export default ForgotPassword
import React, { useState, useEffect } from "react";
import { Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { procurementRequestModel } from "../../schema/schema";
import { apiService, apiService2 } from "../../features/apiService";
import { formCreator } from "../../features/helpers/formhelper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// API End Point
const endPoint = myConfig.addDeptEP;
let fakecount = 0;
const AddProcurementRequest = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('');
    const [deptList, setDeptList] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const [formFields, setFormFields] = useState(procurementRequestModel);
    const [initialValues, setInitialValues] = useState({});
    const [itemname, setItemName] = useState('');
    const [unitprice, setUnitPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [itemList, setItemList] = useState([]);
    const [prmref, setPrmref] = useState('');
    const [dialogSize, setDialogSize] = useState('lg');


    if (props.open.state & !open) {
        setOpen(true);
        setDialogSize('lg');
        getInitialValues();
        setItemList([]);
    }

    function getInitialValues() {
        let ivalues = {};
        for (const item of procurementRequestModel) {
            if (item.field.includes('date')) {
                ivalues[item.field] = new Date().toDateString().split('T')[0];
            } else {
                ivalues[item.field] = '';
            }
        }
        setInitialValues({ ...ivalues })
        return ivalues;
    }


    useEffect(() => {
        apiService(myConfig.getDeptEP)
            .then(response => {
                setDeptList(response.data)
                if (response.data.length == 0) {
                    toast.error("Department list Empty, Please add department first");
                }
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    let modDeptList = [];
    for (const deptItem of deptList) {
        modDeptList.push(deptItem.name);
    }

    useEffect(() => {
        apiService2('GET', myConfig.getInventoryEP)
            .then(response => {
                if (response.data && response.data.length > 0) {
                    setInventoryList(response.data)
                } else {
                    setInventoryList([])
                }
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    let modInventoryList = [];
    for (const cont of inventoryList) {
        modInventoryList.push(cont.itemname);
    }

    const valueDB = { "department": modDeptList };




    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }

    function handleSelect() {

    }

    function handleEditRow(index, row) {
        setItemName(row.itemname);
        setUnitPrice(row.unitprice);
        setQuantity(row.quantity);
        let newList = itemList.filter((item, i) => i !== index);
        setItemList(newList);
    }

    function handleDeleteRow(index) {
        let newList = itemList.filter((item, i) => i !== index);
        setItemList(newList);
    }

    function handleAddItem() {
        if (itemname == '' || unitprice == '' || quantity == '') {
            toast.error("Please fill all fields");
            return;
        } else {
            if (!itemList.find(x => x.itemname == itemname)) {
                let item = inventoryList.find(x => x.itemname == itemname);
                let data = {
                    itemname: itemname, itemcode: item.itemcode, unitprice: unitprice, quantity: quantity, totalamount: unitprice * quantity
                }
                setItemList([...itemList, data]);
                setItemName('');
                setUnitPrice('');
                setQuantity('');
            } else {
                toast.error("Item already added");
                setItemName('');
                setUnitPrice('');
                setQuantity('');
                return;
            }
        }
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={dialogSize}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                {!isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Add Procurement Request</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Formik
                                key={fakecount}
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={Yup.object().shape({
                                    department: Yup.string().required("Required")
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    values['itemlist'] = itemList;
                                    values['totalamount'] = itemList.reduce((a, b) => a + (b['totalamount'] || 0), 0);
                                    values['prmcategory'] = 'Manual';
                                    values['status'] = 'Pending';
                                    values['prmdate'] = new Date();
                                    console.log(values);
                                    apiService2('POST', myConfig.addProcurementRequestEP, values)
                                        .then(resp => {
                                            setDialogSize('sm');
                                            if (resp.data.message) {
                                                setErrorMessage(resp.data.message);
                                                setSubmitionError(true);
                                            } else {
                                                setSubmitionCompleted(true);
                                            }
                                        })
                                        .catch(error => {
                                            setDialogSize('sm');
                                            console.log(["ProcurementRequest Add Failed->", error]);
                                            setSubmitionError(true);
                                            setSubmitionCompleted(true);
                                        });
                                }}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                        resetForm,
                                        setFieldValue
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue, 4)}
                                            </Grid>
                                            <Grid container spacing={4}>
                                                <Grid item xs={4} sm={3}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Item Name'}
                                                        name={'itemname'}
                                                        value={itemname}
                                                        onChange={(e) => {
                                                            setItemName(e.target.value)
                                                            let item = inventoryList.find(x => x.itemname == e.target.value);
                                                            console.log(item);
                                                            setUnitPrice(item.unitcost);
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        select
                                                        defaultValue={''}
                                                        sx={{ display: 'flex', width: "100%" }}>
                                                        {modInventoryList.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={4} sm={3}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Unit Price'}
                                                        name={'unitprice'}
                                                        value={unitprice}
                                                        onChange={(e) => {
                                                            setUnitPrice(e.target.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>
                                                <Grid item xs={4} sm={3}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'Quantity'}
                                                        name={'quantity'}
                                                        value={quantity}
                                                        onChange={(e) => {
                                                            setQuantity(e.target.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name && "Incorrect entry."}
                                                        margin="normal"
                                                        type="text"
                                                        sx={{ display: 'flex', width: "100%" }} />
                                                </Grid>
                                                <Grid item xs={4} sm={3}>
                                                    <Button type="button" sx={{marginTop:3}} variant="contained" color="primary" onClick={handleAddItem} >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow style={{ textAlign: "left" }}>
                                                            <TableCell>Item Name</TableCell>
                                                            <TableCell>Unit Price</TableCell>
                                                            <TableCell>quantity</TableCell>
                                                            <TableCell>Total Amount</TableCell>
                                                            <TableCell>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {itemList.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {row.itemname}
                                                                </TableCell>
                                                                <TableCell > {row.unitprice}</TableCell>
                                                                <TableCell > {row.quantity}</TableCell>
                                                                <TableCell > {row.totalamount}</TableCell>
                                                                <TableCell >
                                                                    <IconButton onClick={() => handleEditRow(index, row)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => handleDeleteRow(index)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <DialogActions>
                                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && !isSubmitionError && (
                    <React.Fragment>
                        <DialogContent>
                            <DialogContentText variant="h6">Procurement Request Added Successfully</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Sorry! Add Procurement Request Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                    Back to app
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default AddProcurementRequest;
import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { workRequestModel } from "../../schema/schema.js";
import { apiService } from '../../features/apiService';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { formCreator } from "../../features/helpers/formhelper";

//function initialiseFields() {return workOrderModel};

// API End Point
const endPoint = myConfig.updateWREP;
let objindex = 0;
let fakecount = 0;

const AcceptWR = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(workRequestModel)
  const { user } = useSelector((state) => state.auth);
  const initValues = getInitialValues();
  const [initialValues, setInitialValues] = useState(initValues);
  const maintTypeList = ["Breakdown", "Corrective"];
  
  function getInitialValues(){
    let ivalues = {};
    const workrequest = props.open.workrequest;
    console.log("Props->", workrequest);
    formFields.forEach( (value)=> {
        ivalues[value['field']] = workrequest[value['field']];
      });
    console.log("Initial Values->", ivalues);
    ++fakecount;
    return ivalues;
  }

  // Get User list
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    apiService(myConfig.getResourceUsersEP, {tenantid:user.tenantid, dept:initialValues['assigneddept']})
      .then (response=> { 
          console.log("get users: Initial Values ->", initialValues, "==>", response.data);
          if (response.data.length == 0) {
            console.log("User List->", response.data.length, initialValues['assigneddept']);
            toast.error("User list Empty...");
            setUserList([]);
          } else { setUserList(response.data);}
          console.log("Users Response->", response);})
      .catch (error => {
          console.error(error);
      }) 
    }, [initialValues]);
    let modUserList = [];
    console.log("USERLIST->", userList.length);
    for(const userItem of userList) {
      modUserList.push(userItem.lastname + ", " + userItem.firstname + ", " + userItem.email );
    }
    const valueDB = {"assignee": modUserList};
  
  // Handle States for the dialog box
  function handleClose() {
    console.log(["handleClose: Setting open to false", open, props.open.user]);
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    props.open.setfn();
    objindex = 0;
  }

  // Handle retry when submit failed
  function handleRetry() {
    //console.log(["handleClose: Setting open to false", open, props.open.user]);
    //setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    //props.open.setfn();
    //objindex = 0;
  }

  function handleClickOpen() {
    // console.log(["Comp: open->", true])
    setSubmitionCompleted(false);
    setOpen(true);
    objindex++;
  }

  // console.log(["Here open->", props.open.state, open, isSubmitionError]);
  if (props.open.state & !open) {
    setOpen(true);
    setInitialValues(initValues);
    //console.log(["OpenCheck: Setting open to false", open, props.open.user]);
  }

  function doSubmission(values) {
    const lworkrequest = props.open.workrequest;
    let retobj = values;
    //setSubmitting(true);
    {(() => {
      for (let key in lworkrequest) {
        let tobj = {};
        if(!retobj.hasOwnProperty(key)) {
          retobj[key] = lworkrequest[key];
        }
      }
    })()};
    retobj['tenantid'] = user.tenantid;
    if (retobj['assignee'] != undefined) {
      retobj['assignee'] = retobj['assignee'].split(",")[2];
    }
    console.log(["Submitted->", retobj]);
    apiService(endPoint, retobj)
      .then ( resp => {
        console.log("WorkRequest updated ");
        setSubmitionCompleted(true);
      })
      .catch (error => {
        console.error(error);
        setSubmitionError(true);
        setSubmitionCompleted(true);
      }) 
  }
  
  // Accept the workrequest created
  function handleAccept(values) {
    console.log("submitting", values);
    if (values.mainttype === "" || values.mainttype == undefined || values.mainttype == null ) {
      toast.warning("Error: Need maintenance type for accepting the workrequest");
      return;
    }
    values['state'] = "Accepted";
    doSubmission(values);
  }

  function handleReject(values) {
    console.log("submitting", values);
    values['state'] = "Rejected";
    //|| values.rejectreason == undefined || values.rejectreason == null
    if (values.rejectreason === "" || values.rejectreason == undefined || values.rejectreason == null ) {
      toast.warning("Error: Add the reason for rejecting the workrequest");
      return;
    }
    doSubmission(values);
  }

  return (
    <React.Fragment>
      <Dialog
        open = {open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        height ='400px'
        >
      {!isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Accept/Reject Work Request </DialogTitle>
          <DialogContent style={{height:'505px'}}>
            <Formik
              //enableReinitialize 
              key={fakecount}
              initialValues= {initialValues}
              onSubmit={(values, { setSubmitting }) => {
                
                const lworkrequest = props.open.workrequest;
                let retobj = values;
                //setSubmitting(true);
                {(() => {
                  for (let key in lworkrequest) {
                    let tobj = {};
                    if(!retobj.hasOwnProperty(key)) {
                      retobj[key] = lworkrequest[key];
                    }
                  }
                })()};
                retobj['tenantid'] = user.tenantid;
                retobj['assignee'] = retobj['assignee'].split(",")[2];
                console.log(["Submitted->", retobj]);
                apiService(endPoint, retobj)
                  .then ( resp => {
                    console.log("WorkOrder updated ");
                    setSubmitionCompleted(true);
                  })
                  .catch (error => {
                    console.error(error);
                    setSubmitionError(true);
                    setSubmitionCompleted(true);
                  }) 
              }}
              // Need to expand the validation schema more later
              validationSchema={Yup.object().shape({ })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <React.Fragment>
                      <Box height={444} width={450} >
                         <TextField
                         id="outlined-basic"
                         error={errors.name && touched.name}
                         label= "wrnumber"
                         name= "wrnumber"
                         value={values.wrnumber}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         helperText=""
                         margin="normal"
                         type="text"
                         InputProps={{
                          readOnly: true,
                        }}
                       />
                       <TextField
                         id="outlined-basic"
                         error={errors.name && touched.name}
                         label= "AssetID"
                         name= "assetid"
                         value={values.assetid}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         helperText=""
                         margin="normal"
                         type="text"
                         InputProps={{
                          readOnly: true,
                        }}
                       />
                        <TextField
                         id="outlined-basic"
                         error={errors.name && touched.name}
                         label= "Description"
                         name= "description"
                         value={values.desc}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         helperText=""
                         margin="normal"
                         type="text"
                         InputProps={{
                          readOnly: true,
                        }}
                        style = {{width: 450}}
                       />
                       <InputLabel id="simple-label">Assignee</InputLabel>
                       <Select
                         id="outlined-basic"
                         error={errors.name && touched.name}
                         name="assignee"
                         label="Assignee"
                         autoWidth= {true}
                         //value={svalue}
                         //value={values.usename}
                         //onChange={(e) => setValue(e.target.value)}
                         value={values.assignee}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         //helperText=""
                         margin="normal"
                         sx={{display:'flex', width:"70%"}}
                         //placeHolder = { usename }
                         labelId="simple-label"
                         //defaultValue={defaultvalue}
                         >
                         {modUserList.map((ivalue ) => (
                           <MenuItem value={ivalue}>
                             {ivalue}
                           </MenuItem>
                         ))}
                       </Select>
                       <InputLabel id="simple-label">Maintenance Type</InputLabel>
                       <Select
                         id="outlined-basic"
                         error={errors.name && touched.name}
                         name="mainttype"
                         label="Maintenance Type"
                         autoWidth= {true}
                         value={values.mainttype}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         //helperText=""
                         margin="normal"
                         sx={{display:'flex', width:"70%"}}
                         //placeHolder = { usename }
                         labelId="simple-label"
                         //defaultValue={defaultvalue}
                         >
                         {maintTypeList.map((ivalue ) => (
                           <MenuItem value={ivalue}>
                             {ivalue}
                           </MenuItem>
                         ))}
                       </Select>
                       <TextField
                         id="outlined-basic"
                         error={errors.name && touched.name}
                         label= "Reason"
                         name= "rejectreason"
                         value={values.reason}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         helperText=""
                         margin="normal"
                         type="text"
                         rows={3}
                         multiline
                    
                         style = {{m:1, width: 450, height: 20}}
                       />
                      </Box>
                    </React.Fragment>
                    <React.Fragment>
                    <DialogActions>
                          
                      <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="button" variant="contained" color="primary"
                        onClick={()=>resetForm({ values: setInitialValues(initValues) })}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                      <Button type="button" variant="contained" color="primary" onClick= { () => handleAccept(props.values) }>
                        Accept
                      </Button>
                      <Button type="button" variant="contained" color="primary" onClick= { () => handleReject(props.values) }>
                        Reject
                      </Button>
            
                    </DialogActions>                  
                    </React.Fragment>
                  </form>
                );
              }}
            </Formik>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionCompleted && (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Thanks! WorkRequest Updated</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Thanks</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                Back
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
      {isSubmitionError && (
        <React.Fragment
        >
          <DialogTitle id="form-dialog-title">Sorry! WorkRequest Assignment Failed</DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">Try Again Later</DialogContentText>
            <DialogActions>
              <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                Back
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  </React.Fragment>
  );
}

export default AcceptWR;
import axios from 'axios'
import { myConfig } from "../../settings.js";

const API_URL = myConfig.loginUserEP;
const API_REGISTER_URL = myConfig.registerUserEP;
const VERIFY_OTP = myConfig.verifyotpEP;

// Register user
const register = async (userData) => {
  const response = await axios.post(API_REGISTER_URL, userData)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL, userData)
  if (response.data && response.data['type'] == 'login') {
    localStorage.setItem('user', JSON.stringify(response.data))
    localStorage.setItem('facilitylist', JSON.stringify(response.data.facility))
    localStorage.setItem('timeZone', JSON.stringify(response.data.timeZone))
  }
  return response.data
}

const verifyOtp = async (userData) => {
 try {
  const response = await axios.post(VERIFY_OTP, userData)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
    localStorage.setItem('facilitylist', JSON.stringify(response.data.facility))
    localStorage.setItem('timeZone', JSON.stringify(response.data.timeZone))
  }
  return response
 } catch (error) {
  console.log("Error=>", error);
  return error.response;
 }
}


// Logout user
const logout = () => {
  console.log("logout received");
  localStorage.removeItem('user');
  localStorage.removeItem('facilitylist');
  localStorage.removeItem('facility');
  console.log("local storage=>", localStorage.getItem('user'));
}

const authService = {
  register,
  logout,
  login,
  verifyOtp
}

export default authService

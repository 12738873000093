import React, { useState, useEffect } from "react";
import { MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { workOrderModel } from "../../schema/schema.js";
import { apiService, apiService2 } from '../../features/apiService';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { formCreator } from "../../features/helpers/formhelper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
// API End Point
const endPoint = myConfig.editWOEndPoint;
let fakecount = 0;

const EditWorkOrder = (props) => {
  const [open, setOpen] = useState(props.open.state);

  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionCompleted2, setSubmitionCompleted2] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(workOrderModel);
  const [status, setStatus] = useState('');
  const { user } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({});
  const [breakBownCodeList, setBreakBownCodeList] = useState([]);
  const [workorder, setWorkOrder] = useState('');
  const [workorder2, setWorkOrder2] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdate2, setIsUpdate2] = useState(false);
  const [isUpdateOthers, setIsUpdateOthers] = useState(false);
  const [inspectionView, setInspectionView] = useState(false);
  const [width, setWidth] = useState('lg');
  const [inspectionList, setInspectionList] = useState([]);
  var date = new Date();
  let from = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
  const [fromDate, setFromDate] = useState(dayjs(from));
  const [isVerify, setIsVerify] = useState(false);
  const [inspetState, setInspectState] = useState('Green');
  const [isSubmition, setSubmition] = useState(false);
  var enumList = ['Ok', 'Not-ok'];


  function getInitialValues() {
    let ivalues = {};
    const worko = props.open.workorder;
    if (worko) {
      formFields.forEach((value) => {
        if (value['field'] == 'assignee') {
          var user = userList.filter((item) => item.email == worko[value['field']]);
          if (user.length > 0) {
            ivalues[value['field']] = user[0].lastname + ", " + user[0].firstname + ", " + user[0].email;
          } else {
            ivalues[value['field']] = worko[value['field']];
          }
        } else if (value['field'] == 'breakdowncode' && worko['type'] == 'Breakdown') {
          var bdcode = breakBownCodeList.filter((item) => item.code == worko[value['field']]);
          if (bdcode.length > 0) {
            ivalues[value['field']] = bdcode[0].code + ": " + bdcode[0].description;
          } else {
            ivalues[value['field']] = worko[value['field']];
          }
        } else {
          ivalues[value['field']] = worko[value['field']];
        }
      });
    }
    setInitialValues(ivalues);
    ++fakecount;
    setWorkOrder(worko);
    return ivalues;
  }

  // Get Department list
  const [deptList, setDeptList] = useState([]);
  useEffect(() => {
    apiService(myConfig.getDeptEP)
      .then(response => {
        setDeptList(response.data)
        if (response.data.length == 0) {
          console.log("Dept List->", response.data.length);
          toast.error("Department list Empty, Please add department first");
        }
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  let modDeptList = [];
  deptList.filter((item) => item.name == workorder.dept);
  for (const deptItem of deptList) {
    modDeptList.push(deptItem.name);
  }

  // Get User list
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    apiService(myConfig.getResource)
      .then(response => {
        setUserList(response.data)
        console.log('response.data', response.data);
        if (response.data.length == 0) {
          toast.error("User list Empty...");
        } else {

        }
        console.log("Response-> user", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  let modUserList = [];
  for (const userItem of userList.filter((item) => item.department == workorder.dept && item.section.includes(workorder.section))) {
    modUserList.push(userItem.lastname + ", " + userItem.firstname + ", " + userItem.email);
  }

  // Get Breakdown Code list
  useEffect(() => {
    apiService2('GET', myConfig.getBreakBownCodeEP)
      .then(response => {
        setBreakBownCodeList(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, [open]);

  let modBreakDownList = [];
  for (const item of breakBownCodeList.filter((item) => item.type == 'BR Code')) {
    modBreakDownList.push(item.code + ": " + item.description);
  }

  let plCodeList = [];
  for (const item of breakBownCodeList.filter((item) => item.type == 'PL Code')) {
    plCodeList.push(item.code + ": " + item.description);
  }

  const valueDB = { "dept": modDeptList, "assignee": modUserList, "breakdowncode": modBreakDownList, "productionlosscode": plCodeList };

  // Handle close dialog
  function handleClose() {
    setOpen(false);
    props.open.setfn(status);
  }

  // Handle retry when submit failed
  function handleRetry() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }

  // Handle open dialog
  if (props.open.state & !open) {
    setOpen(true);
    getInitialValues();
    setIsUpdate(false);
    setIsUpdate2(false);
    setIsUpdateOthers(false);
    setSubmitionError(false);
    setInspectionView(false);
    setSubmitionCompleted(false);
    setInspectionList([])
    setWidth('lg');
    setSubmition(false);
    setInspectState('Green')
    setIsVerify(false);
  }

  function handleSelect(key, value) { }

  function handleClose2() {
    setWidth('lg');
    setIsUpdate(false);
  }

  // Handle update workorder
  function updateWorkorderMethod() {
    setIsUpdate(false);
    setIsUpdate2(false);
    setIsUpdateOthers(false);
    setSubmitionCompleted(true);
    apiService(endPoint, workorder2)
      .then(resp => {
        setWidth('xs');
        if (resp['data'] == 'FP Created') {
          setSubmitionCompleted2(true);
        } else {
          setSubmitionCompleted2(false);
        }
      })
      .catch(error => {
        console.error(error);
        setWidth('xs');
        setSubmitionError(true);
        setSubmitionCompleted(true);
      })
  }

  // Handle update inspection Based Breakdown workorder
  function viewInspectionForm() {
    setWidth('lg');
    setIsUpdate2(false);
    setIsVerify(false);
    setInspectState('Green');
    setInspectionView(true);
  }

  function handlemeasuredValue(event, index) {
    let data = inspectionList;
    data[index]['measuredValue'] = event.target.value;
    setInspectionList([...data])
  }

  function handleRemarkValue(event, index) {
    let data = inspectionList;
    data[index]['remarks'] = event.target.value;
    setInspectionList([...data])
  }

  // Get Inspection Config
  useEffect(() => {
    const workorder = props.open.workorder;
    if (workorder && workorder['assetid']) {
      var data = { assetid: workorder['assetid'] }
      apiService2('POST', myConfig.getInspectionConfigEP, data)
        .then(response => {
          var data = [];
          if (response['data'] && response['data']['inspectionList']) {
            data = response['data']['inspectionList'];
            const aspect = workorder['inspection']['aspect'];
            data = data.filter(x => x.department == workorder['dept'] && aspect.includes(x.aspect))
            var inspec = [];
            data.forEach(res => {
              inspec.push({
                aspect: res.aspect, parameter: res.parameter, inspectionType: res.inspectionType, thresholdValue: res.green, measuredValue: res.measuredValue,
                thresholdBeach: 'No', beachLevel: 'green', remarks: res.remarks, department: res.department, yellow: res.yellow, red: res.red,
                greenCategory: res.greenCategory, yellowCategory: res.yellowCategory, redCategory: res.redCategory,
                greenMin: res.greenMin, greenMax: res.greenMax, redMin: res.redMin, redMax: res.redMax, yellowMin: res.yellowMin, yellowMax: res.yellowMax,
              })
            })
            setInspectionList([...inspec])
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  }, [open]);

  function handleVerifiy() {
    var verify = true;
    var state = 'Green';
    inspectionList.forEach((res) => {
      if (res.inspectionType === 'Measured') {
        if (res.greenCategory === 'Greater than Equal') {
          if (Number(res.measuredValue) < Number(res.thresholdValue)) {
            if (Number(res.measuredValue) >= Number(res.yellowMax) && Number(res.measuredValue) < Number(res.yellowMin)) {
              res.thresholdBeach = 'Yes';
              res.beachLevel = 'yellow';
              if (state != 'Red') {
                state = 'Yellow';
                setInspectState('Yellow');
              }
            } else if (Number(res.measuredValue) >= Number(res.redMax) && Number(res.measuredValue) < Number(res.redMin)) {
              res.thresholdBeach = 'Yes';
              res.beachLevel = 'red';
              state = 'Red';
              setInspectState('Red');
            } else {
              verify = false;
            }
          } else {
            res.thresholdBeach = 'No';
            res.beachLevel = 'green';
          }
        } else {
          if (Number(res.measuredValue) > Number(res.thresholdValue)) {
            if (Number(res.measuredValue) > Number(res.yellowMin) && Number(res.measuredValue) <= Number(res.yellowMax)) {
              res.thresholdBeach = 'Yes';
              res.beachLevel = 'yellow';
              if (state != 'Red') {
                state = 'Yellow';
                setInspectState('Yellow');
              }
            } else if (Number(res.measuredValue) > Number(res.redMin) && Number(res.measuredValue) <= Number(res.redMax)) {
              res.thresholdBeach = 'Yes';
              res.beachLevel = 'Red';
              state = 'Red';
              setInspectState('Red');
            } else {
              verify = false;
            }
          } else {
            res.thresholdBeach = 'No';
            res.beachLevel = 'green';
          }
        }

      } else {
        var s = res.measuredValue;
        if (s.toLocaleLowerCase() === res.thresholdValue.toLocaleLowerCase()) {
          res.thresholdBeach = 'No';
          res.beachLevel = 'green';
        } else {
          res.thresholdBeach = 'Yes';
          res.beachLevel = 'red';
        }
      }
    })
    setInspectionList([...inspectionList]);
    if (!verify) {
      toast("Measured value is incorrect");
    }
    setIsVerify(verify)
  }

  function updateWorkorder() {
    if (inspetState === 'Red' || inspetState === 'Yellow') {
      toast.error("Inspection Failed, Cannot update WorkOrder");
      return;
    } else {
      setSubmition(true);
      workorder2['inspectionList'] = inspectionList;
      apiService(endPoint, workorder2)
        .then(() => {
          setWidth('xs');
          setInspectionView(false);
          setSubmitionCompleted(true);
          // setSubmitionCompleted2(true);
        })
        .catch(error => {
          console.error(error);
          setWidth('xs');
          setInspectionView(false);
          setSubmitionError(true);
          setSubmitionCompleted(true);
        })
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={width}
      >
        {!isSubmitionCompleted && !isUpdate && !isUpdate2 && !isUpdateOthers && !inspectionView && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Update WorkOrder </DialogTitle>
            <DialogContent style={{ height: '600px' }}>
              <Formik
                //enableReinitialize 
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  const lworkorder = props.open.workorder;
                  let retobj = values;
                  setSubmitting(true);
                  {
                    (() => {
                      for (let key in lworkorder) {
                        if (!retobj.hasOwnProperty(key)) {
                          retobj[key] = lworkorder[key];
                        }
                      }
                    })()
                  };
                  retobj['breakdowncode'] = retobj['breakdowncode'].split(":")[0];
                  const cemail = retobj['assignee'].split(",").pop();
                  retobj['assignee'] = cemail.trim();
                  let ldept = "";
                  for (const luser of userList) {
                    if (luser.email === cemail) {
                      ldept = luser.dept;
                      break;
                    }
                  }
                  if (ldept !== "") retobj['dept'] = ldept;
                  setStatus(retobj['state'])
                  retobj['tenantid'] = user.tenantid;
                  if (retobj.state == "Completed" && retobj.breakdowncode == "NA" && retobj.type == "Breakdown") {
                    toast.error("Need BD Code to complete the WorkOrder, cannot update WO");
                    return;
                  }
                  setWorkOrder2(retobj);
                  setWidth('xs');
                  if (retobj.state == "Completed" && (retobj.type == "Breakdown" || retobj.type == "Corrective") && retobj.triger == "Inspection") {
                    setIsUpdate2(true);
                  } else {
                    console.log('Update WorkOrder', retobj);

                    if (retobj.state == "Completed" && (retobj.itemlist?.length == 0 || retobj.resourcelist?.length == 0)) {
                      setIsUpdateOthers(true);
                    } else {
                      setIsUpdate(true);
                    }
                  }

                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({
                  assignee: Yup.string().required("Assignee is required"),
                  state: Yup.string().required("State is required"),
                })}>
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box height={680} >
                        <Grid container spacing={2}>
                          {formCreator("Complex", 'edit', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue)}
                        </Grid>
                      </Box>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && !isSubmitionError && (
          <React.Fragment>
            <DialogContent>
              <DialogContentText variant="h6">WorkOrder updated Successfully</DialogContentText>
              {isSubmitionCompleted2 && (
                <DialogContentText variant="h6">Failure Prevention has been initialized from workorder</DialogContentText>
              )}
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! WorkOrder Update Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Check your entries..</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isUpdate && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Update Asset</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Are you sure you want to update?</DialogContentText>
              <DialogActions >
                <Button type="button" variant="contained" color="secondary" onClick={updateWorkorderMethod}>
                  Yes
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose2}>
                  No
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isUpdate2 && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Update Asset</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Do you want update Inspection?</DialogContentText>
              <DialogActions >
                <Button type="button" variant="contained" color="secondary" onClick={viewInspectionForm}>
                  Yes
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={updateWorkorderMethod}>
                  No
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isUpdateOthers && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Update Asset</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Do you want to Update, Resources, Spares and Notes?</DialogContentText>
              <DialogActions >
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Yes
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={updateWorkorderMethod}>
                  No
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {inspectionView && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Inspection From </DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label='Work Order Id'
                    name='workorderid'
                    value={workorder2.wonumber}
                    helperText=""
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                    type="text"
                    sx={{ display: 'flex', width: "100%" }} />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label='Asset Id'
                    name='assetid'
                    value={workorder2.assetid}
                    helperText=""
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                    type="text"
                    sx={{ display: 'flex', width: "100%" }} />
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      value={fromDate}
                      sx={{ width: '100%', marginTop: 2 }}
                      onChange={(newValue) => setFromDate(newValue)}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ textAlign: "left" }}>
                      <TableCell>Aspect</TableCell>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Inspection Type</TableCell>
                      <TableCell>Threshold Value</TableCell>
                      <TableCell>Measured Value</TableCell>
                      <TableCell>Threshold Beatch (Y/N)</TableCell>
                      <TableCell>Breach Level (Red/Amber)</TableCell>
                      <TableCell colSpan={2}>Remarks</TableCell>
                      <TableCell>Measured by</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inspectionList.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.aspect}
                        </TableCell>
                        <TableCell > {row.parameter}</TableCell>
                        <TableCell > {row.inspectionType}</TableCell>
                        <TableCell > {row.thresholdValue}</TableCell>
                        <TableCell >
                          {
                            row.inspectionType === 'Measured' &&
                            <TextField
                              id="outlined-basic"
                              label=''
                              name='assetid'
                              helperText=""
                              value={row.measuredValue}
                              margin="normal"
                              onChange={(event) => handlemeasuredValue(event, index)}
                              type="text"
                              sx={{ display: 'flex', width: "50%" }} />
                          }
                          {
                            row.inspectionType === 'Visual' &&
                            <TextField
                              id="outlined-basic"
                              label=''
                              name='assetid'
                              onChange={(event) => handlemeasuredValue(event, index)}
                              margin="normal"
                              type="text"
                              select
                              defaultValue={''}
                              sx={{ display: 'flex', width: "100%" }}>
                              {enumList.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          }
                        </TableCell>
                        <TableCell > {row.thresholdBeach}</TableCell>
                        <TableCell >
                          <Button type="button" style={{ backgroundColor: row.beachLevel }}>
                            {row.beachLevel}
                          </Button>
                        </TableCell>
                        <TableCell colSpan={2} style={{ flex: 'd-flex' }} >
                          <TextField
                            id="outlined-basic"
                            label=''
                            name='assetid'
                            value={row.remarks}
                            helperText=""
                            margin="normal"
                            onChange={(event) => handleRemarkValue(event, index)}
                            type="text"
                            sx={{ display: 'flex', width: "100%" }} />
                        </TableCell>
                        <TableCell > {row.department}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <DialogActions>
                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                  Cancel
                </Button>
                {
                  !isVerify && <Button type="button" variant="contained" color="primary" onClick={handleVerifiy}>
                    Verify
                  </Button>
                }
                {
                  isVerify && <Button type="button" variant="contained" color="primary" disabled={isSubmition} onClick={updateWorkorder}>
                    Submit
                  </Button>
                }

              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )
        }
      </Dialog>
    </React.Fragment>
  );
}

export default EditWorkOrder;
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)"

          gap="15px">
          <Box gridColumn="span 6">
            {icon}
          </Box>
          <Box gridColumn="span 6">
            <Typography
              variant="h1"
              sx={{ ml: "10px" }}
              fontWeight="bold">
              {title}
            </Typography>
          </Box>
        </Box>
        {progress != "-1" && (<Box>
          <ProgressCircle progress={progress} />
        </Box>)}
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.blueAccent[500] }}>
          {subtitle}
        </Typography>
        {increase && <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: colors.blueAccent[600] }}>
          {increase}
        </Typography>
        }
      </Box>
    </Box>
  );
};

export default StatBox;

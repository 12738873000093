
import { tokens } from "../../theme";
import { Box, Typography, useTheme, Button, Tab, TabContext, TabList, Tabs, } from "@mui/material";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import Resource from "../resource";
import Facilities from "../facilities";
import Departments from "../departments";
import AssetFamily from "../assetfamily";
import Configuration from "../configuration";
import Contractor from "../contractor";
import Meter from "../meter";
import AccessControl from "../accesscontrol";
import { useSelector } from "react-redux";
import { getAccess } from "../../features/helpers/authhelper";
import BreakBownCode from "../breakdowncode";
import Vendor from "../vendor";
import StoreConfig from "../storeconfig";
import NotificationSett from "../notificationSett";
import Aspect from "../aspect";

const subTitleList = ['Resource', 'Facility', 'Department', 'Asset Family', 'Configuration', 'Contractor', 'Units', 'BD & PL Code', 'Accesscontrol', 'Vendor', 'Store Config','IIoT Config','Notification Config']



const Setup = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = useState('1');
    const [subTitle, setSubTitle] = useState('Resource');
    const { user } = useSelector((state) => state.auth);
    const access = getAccess(user, 'security');
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSubTitle(subTitleList[newValue - 1])
    };

    return (
        <Box m="10px 20px 20px 20px" data-cy="Setup">
            <Header title="Setup" subtitle={subTitle} />
            <Box
                m="10px 0 0 0"
                 height="70vh">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} textColor="primary"
                        indicatorColor="primary" variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example" >
                        <Tab label="Resource" value="1" />
                        <Tab label="Facilities" value="2" />
                        <Tab label="Department" value="3" />
                        <Tab label="Aspect" value="4" />
                        <Tab label="Asset Family" value="5" />
                        <Tab label="Configuration" value="6" />
                        <Tab label="Contractor" value="7" />
                        <Tab label="Units" value="8" />
                        <Tab label="BD & PL Code" value="9" />
                        {access.isView && <Tab label="Access Control" value="10" />}
                        <Tab label="Vendor" value="11" />
                        <Tab label="Store Config" value="12" />
                        <Tab label="IIoT Config" value="13" />
                        <Tab label="Notification Config" value="14" />
                    </Tabs>
                </Box>
                {value == 1 && <Resource />}
                {value == 2 && <Facilities />}
                {value == 3 && <Departments />}
                {value == 4 && <Aspect />}
                {value == 5 && <AssetFamily />}
                {value == 6 && <Configuration />}
                {value == 7 && <Contractor />}
                {value == 8 && <Meter />}
                {value == 9 && <BreakBownCode />}
                {value == 10 && access.isView && <AccessControl />}
                {value == 11 && <Vendor />}
                {value == 12 && <StoreConfig />}
                {/* {value == 13 && <NotificationSett />} */}
            </Box>
        </Box>
    );
}

export default Setup;
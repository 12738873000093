import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import PersonIcon from '@mui/icons-material/Person';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ErrorIcon from '@mui/icons-material/Error';
import HandymanIcon from '@mui/icons-material/Handyman';
import { myConfig } from "../../settings.js";

import { apiService, apiService2 } from '../../features/apiService';
import PieChart from "../../components/PieChart.jsx";
import BarCharts from "../../components/BarChart";
const assetMaintStates = ["Faulty", "Breakdown"];

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [teamcount, setTeamCount] = useState(0);
  const [assetcount, setAssetCount] = useState(0);
  const [assetInMaintCount, setAssetInMaint] = useState(0);
  const [assetNotOperCount, SetAssetNotOper] = useState(0);
  const [openWOCount, setOpenWOCount] = useState(0);
  const [openPOCount, setOpenPOCount] = useState(0);
  const [openWRCount, setOpenWRCount] = useState(0);
  const [pieChart1, setPieChart1] = useState([]);
  const [pieChart2, setPieChart2] = useState([]);
  const [pieChart3, setPieChart3] = useState([]);
  const [barChart1, setBarChart1] = useState({
    xAxis: [],
    series: [],
    xlabel: ""
  });
  const [barChart2, setBarChart2] = useState({
    xAxis: [],
    series: [],
    xlabel: ""
  });
  const [barChart3, setBarChart3] = useState({
    xAxis: [],
    series: [],
    xlabel: ""
  });
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate()
  const dispatch = useDispatch()


  //const { isLoading, isError, message } = useSelector(
  // (state) => state.goals
  //)
  /*
   useEffect(() => {
     //if (isError) {
     //  console.log(message)
     //}
     console.log("User->", user);
     if (!user) {
       navigate('/login')
     }
 
     //dispatch(getGoals())
 
     return () => {
       dispatch(reset())
     }
   }, [user])
 
 */
  // Get total no of users
  useEffect(() => {
    apiService(myConfig.getUserCountEP)
      .then(response => { setTeamCount(response.data['count']) })
      .catch(error => {
        console.error(error);
      })
  }, []);

  // Check for Assets in Maintenance
  function findAssetinMaint(assetList) {
    let assetMaintCount = 0;
    let assetNotOper = 0;
    assetList.forEach((asset) => {
      if (assetMaintStates.indexOf(asset.state) >= 0) {
        assetMaintCount += 1;
      }
      if (asset.state !== "Operational" && asset.state !== "Uncommissioned") {
        assetNotOper += 1;
      }
    });
    setAssetInMaint(assetMaintCount);
    SetAssetNotOper(assetNotOper);
  }

  //Get total no of assets
  useEffect(() => {
    apiService2('POST', myConfig.getAssetsFilterEP)
      .then(response => {
        setAssetCount(response.data.length);
        findAssetinMaint(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  //Get total open workorders
  useEffect(() => {
    apiService(myConfig.getWOOpenCountEP)
      .then(response => {
        setOpenWOCount(response.data.count);
        //findAssetinMaint(response.data);
        console.log("Response->", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  useEffect(() => {
    apiService(myConfig.getOpenWRCountEP)
      .then(response => {
        setOpenWRCount(response.data);
        //findAssetinMaint(response.data);
        console.log("Response-> WR", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  useEffect(() => {
    apiService(myConfig.getOpenPOCountEP)
      .then(response => {
        setOpenPOCount(response.data);
        //findAssetinMaint(response.data);
        console.log("Response-> PO", response);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  useEffect(() => {
    apiService(myConfig.getDashbaordDataEP)
      .then(response => {
        console.log("Dashboard Data->", response.data);
        let deptwisewo = [];
        let timeWisewo = [];
        let typewisewo = [];
        let sectionWisewo = [];
        let breakdownCodeWisewo = [];
        let pendingWo = [];
        deptwisewo.push(["Department", "Count"]);
        for (let item of response.data['deptwisewo']) {
          deptwisewo.push([item.dept, item.count]);
        }
        timeWisewo.push(["Hours", "Count"]);
        for (let item of response.data['timeWisewo']) {
          if (item.category != null && item.category != 'Unknown') {
            timeWisewo.push([item.category, item.count]);
          }
        }
        typewisewo.push(["Type", "Count"]);
        let typeList = ['Breakdown', 'Corrective', 'Preventive', 'Inspection',];
        for (let item of typeList) {
          let data = response.data['typewisewo'].find(x => x.dept == item);
          if (data) {
            typewisewo.push([data?.dept, data?.count]);
          }
        }

        for (let item of response.data['sectionwisewo']) {
          if (item.section != null) {
            sectionWisewo.push({
              wocount : item.count,
              label: item.section,
              section: item.section,
            });
          }
        }
        
        for (let item of response.data['breakdowncodeWiseWo']) {
          if (item.breakdowncode != null) {
            breakdownCodeWisewo.push({
              wocount: item.count,
              label: item.breakdowncode,
              bdcode: item.breakdowncode
            });
          }
        }
        for (let item of response.data['pendingWoDateWise']) {
          if (item && item.category && item.count) {
            pendingWo.push({
              wocount: item.count,
              label: item.category,
              date: item.category
            });
          }
        }
        setPieChart1(deptwisewo);
        setPieChart2(timeWisewo);
        setPieChart3(typewisewo);
        setBarChart1({
          xAxis: [{ scaleType: 'band', dataKey: "section" }],
          //series: sectionWisewo
          series: sectionWisewo,
          xlabel: "section"
        });
        setBarChart2({
          xAxis: [{ scaleType: 'band', dataKey: "breakdowncode" }],
          series: breakdownCodeWisewo,
          xlabel: "bdcode"
        });
        setBarChart3({
          xAxis: [{ scaleType: 'band', dataKey: "category" }],
          series: pendingWo,
          xlabel: "date"
        });
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  return (
    <Box m="20px" data-cy="Dashboard">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" />
        <Box>
          <Button variant="contained" color="primary" >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="10px"
      >
        {/* ROW 1 */}
        <Box gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          className="box">
          <StatBox
            title={assetcount}
            subtitle="Assets"
            progress="-1"
            icon={
              <WebAssetIcon sx={{
                fontSize: "46px",
              }} />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          className="box">
          <StatBox
            title={assetInMaintCount}
            subtitle="Under Maintenance"
            progress="-1"
            icon={
              <HandymanIcon sx={{
                fontSize: "46px",
              }} />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          className="box">
          <StatBox
            title={assetNotOperCount}
            subtitle="Assets: Not Operational"
            progress="-1"
            icon={
              <ErrorIcon sx={{
                fontSize: "46px",
              }} />
            }
          />
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 2"
          //gridRow="span 2"
          backgroundColor={colors.primary[400]}
          className="box"
        >
          <StatBox
            title={openWOCount}
            subtitle="WorkOrders"
            progress="-1"
            icon={
              <WebAssetIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={openPOCount}
            subtitle="Open Procurement orders"
             progress="-1"
            icon={
              <HandymanIcon
                sx={{ color: colors.redAccent[100], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={openWRCount}
            subtitle="Open Work Requests"
             progress="-1"
            icon={
              <HandymanIcon
                sx={{ color: colors.redAccent[100], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px" >
          <Typography
            variant="h5"
            fontWeight="600">
            Department Wise Workorder Count
          </Typography>
          <Box height="350px">
            {pieChart1.length > 0 && <PieChart isDashboard={true} data={pieChart1} colors={['#6e0875', '#139ad4', '#1370d4', '#d4cd13']} />}
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px" >
          <Typography
            variant="h5"
            fontWeight="600">
            Section Wise Workorder Count
          </Typography>
          <Box height="650px">
            {barChart1?.series?.length > 0 && <BarCharts data={barChart1} />}
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px">
          <Typography
            variant="h5"
            fontWeight="600">
            Failure Duration Wise Workorder Count
          </Typography>
          <Box height="350px">
            {pieChart2.length > 0 && <PieChart isDashboard={true} data={pieChart2} colors={['#75082b', '#e65715', '#5f0875', '#152ae6']} />}
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px" >
          <Typography
            variant="h5"
            fontWeight="600">
            Breakdown Code Wise Workorder Count
          </Typography>
          <Box height="350px" >
            {barChart2?.series?.length > 0 && <BarCharts data={barChart2} />}
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px">
          <Typography
            variant="h5"
            fontWeight="600">
            Maintenance Category Wise Workorder Count
          </Typography>
          <Box height="350px">
            {
              pieChart3.length > 0 && <PieChart data={pieChart3} colors={['#e62315', '#e65715', '#08750e', '#152ae6']} />
            }
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px" >
          <Typography
            variant="h5"
            fontWeight="600">
            Pending Workorder Count
          </Typography>
          <Box height="350px">
            {barChart3?.series?.length > 0 && <BarCharts data={barChart3} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

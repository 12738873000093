import { Box, useTheme, Tabs, Tab } from "@mui/material";
import { tokens } from "../../theme";
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import Header from "../../components/Header.jsx";
import Inhouse from "./inhouse.jsx";
import ExternalWorkshop from "./external.jsx";

const subTitleList = ['Workshop', 'External']


const Workshop = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = useState('1');
    const [subTitle, setSubTitle] = useState('Workshop');
    const { user } = useSelector((state) => state.auth);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSubTitle(subTitleList[newValue - 1])
    };
    return (<Box m="10px 20px 20px 20px" data-cy="Workshop">
        <Header title="Workshop" subtitle={subTitle} />
        <Box
            m="10px 0 0 0"
            height="70vh">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} textColor="primary"
                    indicatorColor="primary" variant="scrollable"
                    scrollButtons
                    aria-label="visible arrows tabs example">
                    <Tab label="Workshop" value="1" />
                    <Tab label="External" value="2" />
                </Tabs>
            </Box>
            {value == 1 && <Inhouse />}
            {value == 2 && <ExternalWorkshop />}
        </Box>
    </Box>)
};

export default Workshop;

import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MaterialReactTable } from "material-react-table";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskIcon from '@mui/icons-material/Task';
import TableViewIcon from '@mui/icons-material/TableView.js';
import { useState, useEffect } from "react";
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { tokens } from "../../theme";
import { myConfig } from "../../settings.js";
import AddMaintenanceLib from "./addmaintenancelib";
import { maintenanceLibModel } from "../../schema/schema.js";
import AddTasks from "./addtasks";
import ViewTasks from "./viewtasks";
import { apiService, apiService2 } from '../../features/apiService.js';
import { getAccess } from "../../features/helpers/authhelper";
import ViewMaintenanceLib from './viewmaintenancelib';
import EditMaintenanceLib from './editmaintenancelib';
import DeleteDialog from "../../features/helpers/deletehelper";
import MaintenanceSpares from "./spares";
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";

const MaintenanceLib = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [];
  const [open, setOpen] = useState(false);
  const [viewtaskopen, setViewTaskOpen] = useState(false);
  const [taskopen, setTaskOpen] = useState(false);
  const [vopen, setViewMLOpen] = useState(false);
  const [eopen, setEditOpen] = useState(false);
  const [dopen, setDeleteOpen] = useState(false);
  const [sopen, setSpareOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [mainLibList, setMainLib] = useState([]);
  const [selectedrow, setRow] = useState(-1);
  const { user } = useSelector((state) => state.auth);
  const access = getAccess(user, 'maintenancelibrary');
  const [rowSelection, setRowSelection] = useState({});
  const [selectedMaintenanceLib, setML] = useState({});
  const [trigger, setTrigger] = useState(false);
  console.log('access=>', access);
  
  for (const maintlib of maintenanceLibModel) {
    if (!maintlib['view']) continue;
    columns.push({ accessorKey: maintlib['field'], header: maintlib['label'], size: 150 })
  }

  useEffect(() => {
    apiService(myConfig.getMainLibEP)
      .then(response => {
        var data = response.data;
        data?.forEach(res =>{
          res.cdate = timeZoneConverter(res.createdOn)
          res.suspendedfrom = res.suspendedfrom != null? timeZoneConverter(res.suspendedfrom) : '';
          res.suspendedto = res.suspendedto != null ? timeZoneConverter(res.suspendedto) : '';
        })
        setMainLib(data);
      })
      .catch(error => {
        console.error(error);
      });
  }, [refresh]);

  function handleClose() {
    //console.log("Setting open to false");
    //console.log(["show=>", show]);
    var row = selectedrow;
    setOpen(false);
    setTaskOpen(false);
    setViewTaskOpen(false);
    setEditOpen(false);
    setRefresh(!refresh);
    setViewMLOpen(false);
    setSpareOpen(false);
    setDeleteOpen(false);
    setRow(-1);
    setRowSelection(row);
  }

  function handleClickOpen() {
    //console.log("Setting open to true");
    setOpen(true);
    setRefresh(false);
  }

  function handleAssignTask() {
    //console.log("Setting open to true");
    if (selectedrow != -1) { setTaskOpen(true); }
    else toast.warning("Select a Maintenance Library first ");
  }

  function handleViewTask() {
    //console.log("Setting open to true");
    if (selectedrow != -1) { setViewTaskOpen(true); }
    else toast.warning("Select a Maintenance Library first ");
  }

  function handleClickEdit() {
    setTrigger(!trigger);
    if (selectedrow != -1) { setEditOpen(true); }
    else toast.warning("Select a Maintenance Library first ");
  }

  function handleClickDelete() {
    if (selectedrow != -1) {
      setDeleteOpen(true);
    } else {
      toast.warning("Select a Maintenance Library first ");
    }
  }

  function handleClickView() {
    if (selectedrow != -1) {
      setViewMLOpen(true);
    } else {
      toast.warning("Select a Maintenance Library first ");
    }
  }

  function handleDelete() {
    var mainlib = selectedMaintenanceLib._id;
    setDeleteOpen(false);
    apiService2('POST', myConfig.deleteMainLibEP, { "_id": mainlib })
      .then(response => {
        console.log("Response->", response);
        handleClose();
        if (response.data.message) {
          toast.error(response.data.message);
          return;
        } else {
          toast.success("Maintenance Library deleted successfully");
        }
      })
      .catch(error => {
        console.error(error);
        toast.error("Error deleting Maintenance Library");
      });
  }

  useEffect(() => {
    const mainlib = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const item of mainLibList) {
        if (item._id == mainlib) {
          setML(item);
          setRow(index);
          break;
        }
        ++index;
      }
    } else {

      setML({});
    }
  }, [rowSelection, trigger]);

  function handleClickSpare() {
    if (selectedrow != -1) {
      setSpareOpen(true);
    } else {
      toast.error("Select a row first...");
    }
  }

  function CustomToolbar() {
    return (
      <Box className="mb-1">
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
            Add
          </Button>
        }
        {
          access?.isView && <Button variant="contained" color="primary" startIcon={<TableViewIcon />} onClick={handleClickView}>
            View
          </Button>
        }
        {
          access?.isEdit && <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleClickEdit}>
            Edit
          </Button>
        }
        {
          access?.isDeleted && <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={handleClickDelete}>
            Delete
          </Button>
        }
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<TaskIcon />} onClick={handleAssignTask}>
            Add Tasks
          </Button>
        }
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickSpare}>
            Add Spare
          </Button>
        }
      </Box>
    );
  }
  return (
    <Box m="20px" data-cy="MaintenanceLibrary">
      <Header title="Maintenance Library" subtitle="" />
      <Box
        m="40px 0 0 0"
        height="70vh"
      >
        {CustomToolbar()}
        {tableView(false,columns, mainLibList, setRowSelection, rowSelection, '_id')}
        <AddMaintenanceLib open={{ state: open, setfn: handleClose }} />
        <ViewMaintenanceLib open={{ state: vopen, setfn: handleClose, selectedlib: selectedMaintenanceLib }} />
        <EditMaintenanceLib open={{ state: eopen, setfn: handleClose, selectedlib: selectedMaintenanceLib }} />
        <AddTasks open={{ state: taskopen, setfn: handleClose, rowid: selectedMaintenanceLib }} />
        <ViewTasks open={{ state: viewtaskopen, setfn: handleClose, rowid: selectedMaintenanceLib }} />
        <DeleteDialog open={{ state: dopen, setfn: handleClose, setdl: handleDelete, message: 'Maintenance Library' }}></DeleteDialog>
        <MaintenanceSpares open={{ state: sopen, setfn: handleClose, maintenance: selectedMaintenanceLib }} />
      </Box>
    </Box>
  );
};

export default MaintenanceLib;

import React, { useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { useSelector } from 'react-redux';
import { configurationModel } from "../../schema/schema";
import { apiService } from "../../features/apiService";

import { formCreator } from "../../features/helpers/formhelper";

// API End Point

let fakecount = 0;
const AddConfiguration = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const { user } = useSelector((state) => state.auth);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [initialValues, setInitialValues] = useState({});


    function getInitialValues() {
        let ivalues = {};
        for (const item of configurationModel) {
            if (item.hasOwnProperty("skip")) if (item.skip) continue;
            ivalues[item.field] = '';
        }
        setInitialValues({ ...ivalues })
    }

    if (props.open.state & !open) {
        setOpen(true);
        getInitialValues();
    }

    // Handle States for the dialog box
    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleTryAgain() {
        setSubmitionError(false);
        setErrorMessage('');
    }


    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            {!isSubmitionCompleted && !isSubmitionError && (
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Add Configuration</DialogTitle>
                    <DialogContent>
                        <Formik
                            key={fakecount}
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                apiService(myConfig.addConfiguration, values)
                                    .then(resp => {
                                        if (resp.data.message) {
                                            setErrorMessage(resp.data.message);
                                            setSubmitionError(true);
                                        } else {
                                            setSubmitionCompleted(true);
                                        }
                                    })
                                    .catch(error => {
                                        console.log(["Configuration Add Failed->", error]);
                                        setSubmitionError(true);
                                        setSubmitionCompleted(true);
                                    });
                            }}>
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    handleReset,
                                    resetForm
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Box>
                                            <Grid container spacing={2}>
                                                {formCreator("Complex", 'add', configurationModel, initialValues, values, handleChange, handleBlur, touched, errors, null, null, false, null, 6)}
                                            </Grid>
                                        </Box>
                                        <DialogActions>
                                            <Button
                                                type="button"
                                                variant="contained" color="primary"
                                                onClick={() => resetForm()}
                                                disabled={!dirty || isSubmitting}>
                                                Reset
                                            </Button>
                                            <Button type="submit" variant="contained" color="primary" onClick={handleClose}  >
                                                Cancel
                                            </Button>
                                            <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting} >
                                                Submit
                                            </Button>
                                        </DialogActions>
                                    </form>
                                );
                            }}
                        </Formik>


                    </DialogContent>
                </React.Fragment>
            )}
            {isSubmitionCompleted && !isSubmitionError && (
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Configuration Added</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6">Thanks</DialogContentText>
                        <DialogActions>
                            <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                Back to app
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
            )}
            {isSubmitionError && (
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Sorry! Add Configuration Failed</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h6"> {errorMessage ? errorMessage : 'Try Again Later'}</DialogContentText>
                        <DialogActions>
                            <Button type="button" variant="contained" color="secondary" onClick={handleTryAgain}>
                                Back to app
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>
            )}
        </Dialog>
    );
}

export default AddConfiguration;
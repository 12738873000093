import { Box, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from "react";
import { GridToolbarContainer } from '@mui/x-data-grid';
import axios from "axios";
import { myConfig } from "../../settings.js";
import AddInventory from "./inventoryadd";
import { inventoryModel } from "../../schema/schema.js";
import { useSelector } from 'react-redux';
import { getAccess } from "../../features/helpers/authhelper";
import { MaterialReactTable } from "material-react-table";
import { apiService2 } from "../../features/apiService";
import EditInventory from "./edit";
import { toast } from 'react-toastify'
import { timeZoneConverter } from "../../features/helpers/commonhelper.js";
import { tableView } from "../../features/helpers/table.js";

const Inventory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [];
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [inventory, setInventory] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const access = getAccess(user, 'itemmaster');
  const [selectedrow, setRow] = useState(-1);
  const [rowSelection, setRowSelection] = useState({});

  for (const item of inventoryModel) {
    if (item.hasOwnProperty("view")) {
      if (!item.view) continue;
    }
    columns.push({ accessorKey: item['field'], header: item['label'], size: 150 });
  }

  useEffect(() => {
    apiService2('GET', myConfig.getInventoryEP)
      .then(response => {
        var inventoryList = response.data;
        inventoryList.forEach((item, index) => {
          item.cdate = timeZoneConverter(item.createdOn)
          item.vendor = item.vendor ? item.vendor.supplierName : '';
          item.store = item.store ? item.store.name : '';
        })
        setInventoryList(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }, [refresh]);

  useEffect(() => {
    const wrnumber = Object.keys(rowSelection)[0];
    const selected = Object.values(rowSelection)[0];
    let index = 0;
    if (selected) {
      for (const temp of inventoryList) {
        if (temp._id == wrnumber) {
          console.log(temp);
          setInventory(temp);
          setRow(index);
          break;
        }
        ++index;
      }
    } else {

      setInventory({});
    }
  }, [rowSelection]);

  function handleClose() {
    setOpen(false);
    setOpenEdit(false);
    setOpenDelete(false);
    setRefresh(!refresh);
  }

  function handleClickEdit() {
    if (selectedrow >= 0) {
      setOpenEdit(true);
      setRefresh(false);
    } else toast.warning("Select a any one StoreConfig first ");
  }

  function handleClickDelete() {
    if (selectedrow >= 0) {
      setOpenDelete(true);
    } else toast.warning("Select a any one StoreConfig first ");
  }

  function handleClickOpen() {
    //console.log("Setting open to true");
    setOpen(true);
  }

  function handleDelete() {
    apiService2('POST', myConfig.deleteInventoryEP, { "_id": inventory._id, })
      .then(response => {
        toast.success("Inventory deleted successfully");
        handleClose();
      })
      .catch(error => {
        console.error(error);
        toast.error("Inventory deletion failed");
      })
  }

  function CustomToolbar() {
    return (
      <Box className="mb-1">
        {
          access?.isCreate && <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
            Add
          </Button>
        }
      </Box>
    );
  }

  return (
    <Box m="20px" data-cy="Inventory">
      <Header title="Item Master" subtitle="" />
      <Box
        m="40px 0 0 0"
        height="70vh"
      >
        {CustomToolbar()}
        {tableView(
          false, columns, inventoryList, setRowSelection,
          rowSelection, '_id',
          true,
          ({ row, table }) => [
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              {
                access.isEdit && <IconButton
                  color="secondary"
                  onClick={() => {
                    setInventory({ ...row.original })
                    setOpenEdit(true);
                  }}>
                  <EditIcon />
                </IconButton>
              }
              {
                access.isDeleted && <IconButton
                  color="error"
                  onClick={() => {
                    setInventory({ ...row.original })
                    setOpenDelete(true);
                  }}>
                  <DeleteIcon />
                </IconButton>
              }
            </Box>
          ])
        }
        <AddInventory open={{ state: open, setfn: handleClose }} />
        <EditInventory open={{ state: openEdit, setfn: handleClose, inventory: inventory }} />
        <Dialog
          open={openDelete}
          fullWidth={true}
          maxWidth={'xs'}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Delete Inventory</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Are you sure you want to Delete?</DialogContentText>
              <DialogActions sx={{
                
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
                <Button type="button" variant="contained" color="secondary" onClick={handleDelete}>
                  Confirm
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Inventory;
